import React, {useEffect, useState} from 'react';
import SubscriptionsView from "./SubscriptionsView";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getAllPlansData, submitTransactionDetails} from "../userActions";
import {isEmpty} from "../../utils/validations";
import store from "../../store";
import {setNotAccessible, setUserProfile} from "../../user/userReducer";
import {getMyProfile} from "../../user/userActions";
const Subscriptions = (props) => {
    const [subscriptionsState, setSubscriptionsState] = useState({
        errorInApi : '',
        plans : [],
    });

    useEffect(()=>{
        const {myPendingRequestDetails, profile} = props;
        if(isEmpty(myPendingRequestDetails) && !isEmpty(profile?.accountId) && !isEmpty(profile)){
           props.history.push('/')
        }else{
            getAllPlans()
        }
    },[props.profile]);

    const getAllPlans = () => {
        getAllPlansData().then(res => {
            if (res?.success) {
                setSubscriptionsState(prevModelData => ({
                    ...prevModelData,
                    plans: res.data?.plans,
                    errorInApi: ''
                }));
            } else {
                if (res?.statusCode === 403) {
                    store.dispatch(setNotAccessible())
                } else {
                    setSubscriptionsState(prevModelData => ({
                        ...prevModelData,
                        errorInApi: res?.__error
                    }));
                }
            }
        })
    };

    const submitAcademicPlan = () => {
        const{account} = props;

        let data = {
            planCode : 'ACADEMIC'
        };
        setSubscriptionsState((prevState) => ({
            ...prevState,
            isshowLoader: true
        }));

        submitTransactionDetails(data,account?.id).then(res => {
            if (res.success) {
                getMyProfile()
                    .then((res) => {
                        if(res?.statusCode === 200){
                            store.dispatch(setUserProfile(res?.data));
                        }
                        setTimeout(()=>{
                            setSubscriptionsState((prevState) => ({
                                ...prevState,
                                isshowLoader: false
                            }));
                            props.history.replace('/payment-status')
                        },200);
                    });
            }
            else {
                setSubscriptionsState((prevState) => ({
                    ...prevState,
                    errorInModelApi : res.__error,
                    isshowLoader: false
                }));
            }
        });

    };

    return (
        <SubscriptionsView
            {...props}
            {...subscriptionsState}
            submitAcademicPlan={submitAcademicPlan}
        />
    );
};
const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isLGView: state.appState.deviceInfo.isLGView,
    isMDView: state.appState.deviceInfo.isMDView,
    account : state.accountState.account,
    profile : state.userState.profile,
    myPendingRequestDetails : state.accountState.myPendingRequestDetails,
});

export default connect(mapStateToProps, {})(withRouter(Subscriptions));