import React from 'react';
import PageNotFoundView from './pageNotFoundView';

const PageNotFound = () => {
    return (<PageNotFoundView/>);
}

PageNotFound.propTypes = {};

export default PageNotFound;
