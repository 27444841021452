import React, {useEffect, useState} from 'react';
import UserDetailsView from "./UserDetailsView";
import {isEmpty} from "../../../utils/helperCommon";

function UserDetails(props) {

    const [userDetailsState, setUserDetailsState] = useState({
        roleData: [],
        groupData: [],
        memberId: null,
        isLeaveDialogOpen: false,
        isOwnerView: false,
        UIState: "",
        isDeleteDialogOpen: false,
        deletingObj: {},
        isLeaveUserDialogOpen: false,
        isRemoveUserDialogOpen: false,
        userDetails : {}
    });
    useEffect(() => {
        const accountId = props.match?.params?.accountId;
        const memberId = props.match?.params?.userId;

        if (memberId) {
            setUserDetailsState(prevuserDetailsState => ({
                ...prevuserDetailsState,
                memberId: memberId
            }));
        }

        // getUserInfo(memberId, accountId);

    }, []);


    const getUserInfo = (memberId, accountId) => {
        getUsersInfo(memberId, accountId)
            .then((res) => {
                if (res.success && !isEmpty(res.data)) {
                    if (res?.statusCode === 200) {
                        store.dispatch(getUserData(res.data));
                        setUserDetailsState(prevuserDetailsState => ({
                            ...prevuserDetailsState,
                            UIState: userLists.USER_DETAILS

                        }));
                    }
                } else {
                    setUserDetailsState(prevuserDetailsState => ({
                        ...prevuserDetailsState,
                        UIState: userLists.NO_CONTENT
                    }));
                }
            })
    };

    const handleOwnerView = () => {
        setUserDetailsState(prevuserDetailsState => ({
            ...prevuserDetailsState,
            isOwnerView: !userDetailsState.isOwnerView,
        }));
    };

    const closeLeaveDialog = () => {
        setUserDetailsState(prevuserDetailsState => ({
            ...prevuserDetailsState,
            isLeaveDialogOpen: false,
        }));
    };

    const closeDeleteDialog = (action) => {
        switch (action) {
            case 'REMOVE_ROLE':
                setUserDetailsState(prevuserDetailsState => ({
                    ...prevuserDetailsState,
                    isDeleteDialogOpen: false,
                }));
                break;
            case 'REMOVE_USER':
                setUserDetailsState(prevuserDetailsState => ({
                    ...prevuserDetailsState,
                    isRemoveUserDialogOpen: false
                }));
                break;
            case 'LEAVE_ACCOUNT':
                setUserDetailsState(prevuserDetailsState => ({
                    ...prevuserDetailsState,
                    isLeaveUserDialogOpen: false
                }));
                break;
            default:
        }
    };

    const openDeleteDialog = (action, deletingObj) => {
        switch (action) {
            case 'REMOVE_ROLE':
                setUserDetailsState(prevuserDetailsState => ({
                    ...prevuserDetailsState,
                    isDeleteDialogOpen: true,
                }));
                break;
            case 'REMOVE_USER':
                setUserDetailsState(prevuserDetailsState => ({
                    ...prevuserDetailsState,
                    isRemoveUserDialogOpen: true
                }));
                break;
            case 'LEAVE_ACCOUNT':
                setUserDetailsState(prevuserDetailsState => ({
                    ...prevuserDetailsState,
                    isLeaveUserDialogOpen: true
                }));
                break;
            default:
        }

        setUserDetailsState(prevuserDetailsState => ({
            ...prevuserDetailsState,
            deletingObj: deletingObj
        }));
    };

    const removeUser = () => {
        const {userDetails, account} = props;

        // deleteUser(userDetails.id, userDetails.accountId)
        //     .then(res => {
        //         if (res.success) {
        //             store.dispatch(showToastMessage('success', 'User ' + getFormattedUserName(userDetails) + ' is removed from ' + account?.name + ' account.'));
        //             props.history.replace('/accounts/' + userDetails?.accountId + '/users-roles-groups');
        //
        //             setUserDetailsState(prevuserDetailsState => ({
        //                 ...prevuserDetailsState,
        //                 isRemoveUserDialogOpen: false,
        //                 deletingObj: {}
        //             }));
        //         }
        //         store.dispatch(showToastMessage('danger', res?.__error));
        //     })

    };

    const leaveAccount = () => {
        const {account} = props;
        // leaveAccountData(account?.id)
        //     .then((res) => {
        //         switch (res?.statusCode) {
        //             case 204:
        //                 store.dispatch(resetAccountsList());
        //                 store.dispatch(resetAccountData());
        //                 store.dispatch(updateAccounts());
        //                 localStorage.removeItem("account_" + [props.profile?.email]);
        //                 store.dispatch(showToastMessage('success', 'You have left ' + account.name + ' account.'));
        //                 setTimeout(() => {
        //                     props.history.push('/accounts/dashboard');
        //                 },100);
        //                 break;
        //             case 400:
        //             case 401:
        //             case 403:
        //                 store.dispatch(showToastMessage('warning', res.__error));
        //                 break;
        //             default:
        //                 store.dispatch(showSystemErrorBlock('warning', res.__error));
        //                 break;
        //         }
        //     })
        //     .catch((err) => {
        //         store.dispatch(showSystemErrorBlock(err))
        //     })

    };

    const handleRemove = (action, role) => {
        if (action === 'REMOVE_USER') {
            removeUser(role);
        }

    };

    // const {account, profile} = props;
    // const {UIState} = userDetailsState;

    // if (isEmpty(UIState)) {
    //     return (<DLLoader type={'screen'} isVisible={true}/>)
    // }

    // if (UIState === userLists.NO_CONTENT) {
    //     return <PageNotFound {...props}/>
    // }

    return (
        <UserDetailsView
            {...props}
            {...userDetailsState}
            handleOwnerView={handleOwnerView}
            closeLeaveDialog={closeLeaveDialog}
            openDeleteDialog={openDeleteDialog}
            closeDeleteDialog={closeDeleteDialog}
            handleRemove={handleRemove}
            leaveAccount={leaveAccount}
        />
    );
}

export default UserDetails;