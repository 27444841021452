import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {setPendingInvitations, setUserAuthStatus} from "../../../user/userReducer";
import {getMyPendingInvitation} from "../../../account/userActions";
import {connect} from "react-redux";
import ResAppBarView from "./ResAppBarView";
import {isEmpty} from "../../../utils/validations";
import {getMyAccount, logoutUser} from "../../../user/userActions";
import {refreshWindowScrollElement} from "../../../utils/helper";

function ResAppBar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [resAppBarState, setResAppBarState] = useState({
        searchOptions: [],
        searchOptionsFound: false,
        isSearchInProgress: false
    });

    const [anchorSettings, setAnchorSettings] = useState(false);
    const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);
    const [showCreatAccountButton, setshowCreatAccountButton] = useState(false);
    const [showChooseAccount, setShowChooseAccount] = useState(false);
    const {setPendingInvitations} = props;

    useEffect(() => {

        if (props.userAuthStatus === 'AUTH_STATUS_AUTHORIZED') {
                    getMyPendingInvitation()
                        .then((res) => {
                            if (!isEmpty(res.data?.invitations)) {
                                setPendingInvitations(res.data?.invitations)
                                props.history.push('/invite')
                            } else {
                                if(props.history?.location?.pathname === '/user/login'){
                                    props.history.push('/')
                                }

                            }
                        });
        }
    }, [props.userAuthStatus]);

    const handleMenuItemsOpen = event => {
        setAnchorSettings(event.currentTarget);
    };

    const handleMenuItemsClose = () => {
        setAnchorSettings(null)
    };

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };

    const handlingRedirection = () => {
        getMyAccount()
            .then((res) => {
                if((res?.success) && !isEmpty(res.data)){
                    props.history.push('/model-executions/new')
                }
            });
    };

    return (
        <ResAppBarView
            {...props}
            {...resAppBarState}
            anchorEl={anchorEl}
            anchorSettings={anchorSettings}
            handleMenuItemsOpen={handleMenuItemsOpen}
            handleMenuItemsClose={handleMenuItemsClose}
            handleItemOpen={handleItemOpen}
            handleItemClose={handleItemClose}
            isDeleteDialogOpen={false}
            showChooseAccount={showChooseAccount}
            refreshWindowScrollElement={refreshWindowScrollElement}
            handlingRedirection={handlingRedirection}
        />
    );
}

ResAppBar.propTypes = {

};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile:state.userState.profile,
    allPendingInvitations:state.userState.allPendingInvitations,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isDrawerOpen: state.appState.isDrawerOpen,
    currentPathname: state.appState.currentPathname,
    prevLocation: state.appState.prevLocation,
    account: state.accountState.account,
});


export default connect(mapStateToProps, {
    setUserAuthStatus, logoutUser,
    setPendingInvitations,
    refreshWindowScrollElement
}) (withRouter(ResAppBar))
