import React from 'react';
import {isEmpty} from "../../utils/validations";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {getTrimmedName} from "../../utils/helper";

function InviteView(props) {

    const {inviteDetails, invitedUserDetails,acceptInvite, errorInApi} = props;
    if(isEmpty(inviteDetails)){
        return (<DLLoader type={"screen"} isVisible={true}/>)
    }

    return (
        <div className='pageContainer'>
            {!isEmpty(errorInApi) && <div className='py-2 align-items-center d-flex error-panel'>
                <div className='error-vertical-line'/>
                {errorInApi}
            </div>}
            <div className='d-flex flex-column px-4 align-items-center justify-content-center bg-white h-100 w-100'
                 style={{
                     minHeight: !isEmpty(errorInApi) ? 'calc(100vh - 144px)' : 'calc(100vh - 64px)'
                 }}>
                <div className='px-lg-5 py-lg-4 p-3 text-center' style={{
                    boxShadow:'0px 0px 6px #00000029',
                    border: '1px solid #E0E0E0',
                    borderRadius: '8px'
                }}>
                    <i style={{fontSize: '50px'}} className='fa fa-user-check mb-4 default-icon'/>
                    <div style={{
                        fontSize : 'var(--font-size-xl)',
                        fontWeight : 'var(--font-weight-semibold)'
                    }}>
                        Join Account
                    </div>

                    <div className='text-center pt-2 d-flex' style={{gap:5}}>
                        <div>{getTrimmedName(invitedUserDetails?.firstName + ' ' + invitedUserDetails?.lastName, 30)}</div>
                        has invited you to join
                        <div style={{fontWeight : 500}}>{inviteDetails?.accountName}</div>
                    </div>

                    <div className='mt-4 pt-2 mb-2 text-center'>
                        <button
                            className='py-2 px-3 ms-2 buttonStyle primary-button'
                            style={{width: "auto"}}
                            onClick={() => {
                                acceptInvite()
                            }}
                        >
                            <span className='px-1'>Accept Invite</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InviteView;