import React from 'react';
import PageNotAccessibleView from "./PageNotAccessibleView";
import {connect} from "react-redux";
import {setPrevLocation, setPrevRoutes} from "../../actions/appActions";
import {withRouter} from "react-router-dom";
import {userConst} from "../../../user/userConsts";
import {isEmpty} from "../../../utils/helperCommon";

const PageNotAccessible = (props) => {

  const handleClick = () => {
    const {userAuthStatus, profile} = props;
    if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED && isEmpty(profile?.SystemUser)) {
      window.location.href = process.env.REACT_APP_API_URL + '/';
      return;
    }
    props.history.push('/user/login');
  };
    return (
      <PageNotAccessibleView
        {...props}
        handleClick={handleClick}
      />
    );
};


PageNotAccessible.propTypes = {};
const mapStateToProps = (state) => ({
  userAuthStatus: state.userState.userAuthStatus,
  profile: state.userState.profile,
  allPendingInvitations:state.userState.allPendingInvitations,
});

export default connect(mapStateToProps, {
  setPrevLocation,
  setPrevRoutes
})(withRouter(PageNotAccessible));
