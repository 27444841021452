import React from 'react';
import {isEmpty} from "../../utils/helperCommon";
import {numberWithCommas} from "../../utils/helper";

function SubscriptionsView(props) {
    const {errorInApi, plans, submitAcademicPlan} = props;

    const getPermissionUi = (features) => {
       if(!isEmpty(features)){
           return features.map((feature, index) => {
               return (
                   <div key={index}>
                       <div className='px-3 pt-3'>
                           {feature}
                       </div>
                   </div>
               )
           })
       }
    };

    const renderPlanPrice = (price) => {
        if(price === 0){
            return <span className='text-end'
                         style={{
                             fontWeight:'var(--font-weight-semibold)',
                             lineHeight : 0.9,
                             fontSize: 'var(--font-size-md)'
                         }}>
                        Free
                    </span>
        }
        return (
            <div className=''
                 style={{minHeight: "60px"}}>
                <div className='d-flex align-items-end justify-content-start'
                     style={{gap: 5}}>
                    <span className='text-end'
                        style={{
                        fontSize:'var(--font-size-xxxl)',
                            color: 'var(--primary)',
                        fontWeight:'var(--font-weight-semibold)', lineHeight : 0.9
                    }}>
                        $$
                    </span>
                    {/*/mo*/}
                </div>
            </div>
        )
    };

    const renderPlanUI = () => {
        return plans.map((plan, index) => {
            return (
                <div className='col-xl-3 col-md-6 col-12 my-3'
                     key={index}>
                    <div className='h-100 px-0'>
                        <div className='d-flex flex-column justify-content-between mx-0 h-100'>
                            <div className='h-100 bg-white large-border-radius' style={{maxWidth: 400}}>
                                <div className='mt-3 mx-2 px-3 '
                                     style={{
                                         fontWeight: 'var(--font-weight-bold)',
                                         fontSize: 'var(--font-size-xl)'
                                     }}>
                                    {(plan?.code !== 'ACADEMIC') ? plan?.name : (plan?.name + ' Plan')}
                                </div>
                                <div className='mt-3 mx-2 px-3'>
                                    {renderPlanPrice(plan?.monthlyPrice)}
                                </div>
                                <div className={'mx-2'}>
                                    {getPermissionUi(plan?.features)}
                                </div>
                                <div className='px-3 mx-2 mt-4 mb-4'>
                                    {(plan?.code === 'ACADEMIC') ?
                                        <button
                                            className='buttonStyle success-button'
                                            style={{width: '100px'}}
                                            onClick={() => {submitAcademicPlan()}}>
                                            <span className='px-1'>Proceed</span>
                                        </button>
                                        :
                                        <button
                                            className='buttonStyle success-button'
                                            style={{width: '100px'}}
                                            onClick={() => {props.history.push('/transaction',plan?.code)}}>
                                            <span className='px-1'>Continue</span>
                                        </button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    };

    return (
        <div className='page-content-bg p-0 m-0' style={{minHeight: 'calc(100vh - 50px)'}}>
            <div className='pageContainer mb-3 mt-0 page-content-bg p-0'>
                <div className='page-content-bg mt-0'>
                    <div className='d-flex align-items-center w-100 justify-content-between pt-4'>
                        <div className='page-header' style={{fontWeight: 400}}>
                            Choose Your Plan
                        </div>
                    </div>
                    {!isEmpty(errorInApi) && <div className='py-2 align-items-center d-flex error-panel'>
                        <div className='error-vertical-line'/>
                        {errorInApi}
                    </div>}
                    <div className='mt-3 d-flex row align-items-center w-100 justify-content-start'>
                        {!isEmpty(plans) && renderPlanUI()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionsView;