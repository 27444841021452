import React, {useEffect, useState} from 'react';
import InviteView from "./InviteView";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {isEmpty} from "../../utils/validations";
import {acceptInvitation} from "../userActions";
import {setNotAccessible, setPendingInvitations} from "../../user/userReducer";
import store from "../../store";

const Invite = (props) => {

    const [inviteState,setInviteState] = useState({
        inviteDetails:'',
        invitedUserDetails: '',
        errorInApi : ''
    });

    useEffect(()=>{
        const invitations = props.myPendingInvitations;
        if(!isEmpty(invitations)){
            setInviteState(prevData => ({
                ...prevData,
                inviteDetails : invitations[0],
                invitedUserDetails : invitations[0]?.invitedByMember?.User
            }));
        }

    },[props.myPendingInvitations]);

    const acceptInvite = () => {
        const {account, setPendingInvitations} = props;
        const {inviteDetails} = inviteState;

        let data = {
            "accountId" : inviteDetails?.accountId
        };

        acceptInvitation(data,account?.id).then(res => {
            if (res.success) {
                setPendingInvitations();
                props.history.replace('/');
            }
            else {
                if (res?.statusCode === 403) {
                    store.dispatch(setNotAccessible())
                } else {
                    setInviteState(prevData => ({
                        ...prevData,
                        errorInApi : res?.__error
                    }));
                }
            }

        });
    };

    return(
        <InviteView
            {...props}
            {...inviteState}
            acceptInvite={acceptInvite}
        />
    )

};
const mapStateToProps = (state) => ({
    myPendingInvitations : state.accountState.myPendingInvitations,
    account : state.accountState.account,
});

export default connect(mapStateToProps, {
    setPendingInvitations
})(withRouter(Invite));