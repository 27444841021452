import {lazy} from "react";
const UserRoutes = lazy(() => import('../../user/userRoutes'));
const ModelRoutes = lazy(() => import('../../model/modelRoutes'));

const modules = [
    {
        routeProps: {
            path: '/user',
            component: UserRoutes,
        },
        name: 'User',
    },
    {
        routeProps: {
            path: '',
            component: ModelRoutes,
        },
        name: 'Other',
    }
];

export default modules;