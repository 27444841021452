import React from 'react';
import {NavLink} from 'react-router-dom';
import {userConst} from "../../../user/userConsts";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {isEmpty} from "../../../utils/validations";
import {getTrimmedName} from "../../../utils/helper";
import appTheme from "../../../assets/appTheme";


function ResAppBarView(props) {

    const {userAuthStatus, isMobileView, handleMenuItemsOpen, handleItemClose,handlingRedirection, profile,allPendingInvitations} = props;
    const checkReadOnlyAccess = (buttonName) => {
        const {account} = props;
        let memberRole = account?.memberRole;

        if (isEmpty(memberRole)) {
            return false
        }

        switch (memberRole?.name) {
            case 'IT'://campaign, segment, email template, account overview, billing and invoice, subscritpion
                return buttonName === 'Campaigns' || buttonName === 'Segments' || buttonName === 'Templates' ||
                    buttonName === 'AccountOverview' || buttonName === 'BillingInvoice' || buttonName === 'Subscription';
            case 'ANALYST'://campaign ,  setting, email templates, account overview, subscription, billing and invoice
                return buttonName === 'Campaigns' || buttonName === 'Templates' || buttonName === 'DataSetup' ||
                    buttonName === 'AccountOverview' || buttonName === 'BillingInvoice' || buttonName === 'Subscription' || buttonName === 'Settings';
            case 'DESIGNER': //campaign, segment, data setup, account overview, billing and invoice, setting, subscription
                return buttonName === 'Campaigns' || buttonName === 'Segments' || buttonName === 'DataSetup' ||
                    buttonName === 'AccountOverview' || buttonName === 'BillingInvoice' || buttonName === 'Subscription' || buttonName === 'Settings';
            case 'MARKETING'://account overview, billing and invoice, subscritpion
                return buttonName === 'AccountOverview' || buttonName === 'BillingInvoice' || buttonName === 'Subscription';
            case 'VIEWER'://account overview, billing and invoice, subscritpion
                return buttonName === 'BillingInvoice';
            case 'ADMIN'://account overview, billing and invoice, subscritpion
                return buttonName === 'BillingInvoice';
            default:
                return false
        }

    };

    const renderSettingMenu = () => {
        const {account, handleSettingIconClick, anchorSettings, handleMenuItemsClose, isLoggedOutScreen} = props;

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED || isEmpty(account) || isLoggedOutScreen) {
            return;
        }
        let menuOptions = [
            {
                label: 'Account Overview',
                isDisabled: false,
                icon: '',
                onClick: () => {
                    props.history.push('/account-overview');
                },
                isVisible: (account?.isAdmin),
            },
            {
                label: 'User Management',
                isDisabled: false,
                icon: '',
                onClick: () => {
                    // setSwitchButton('User');
                    props.history.push('/user-management');
                },
                isVisible: (account?.isAdmin),
            }
        ];

        let icon = (
            <div className='ms-lg-2 ms-xl-3 ms-1  mt-1 cursor'
                 onClick={handleMenuItemsOpen}>
                <i className='fa fa-cog icon-size default-icon' style={{fontSize: 20}}/>
            </div>
        );
        let menu = (
            <Menu
                id="items-menu"
                anchorEl={anchorSettings}
                getcontentanchorel={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={Boolean(anchorSettings)}
                onClose={handleMenuItemsClose}
                className="p-2"
                style={{
                    top: '12px'
                }}
                disableAutoFocusItem
                PaperProps={{
                    style: {
                        marginLeft: '0px'
                    }
                }}
                MenuListProps={{
                    style: {
                        paddingTop: 6,
                        paddingBottom: 6,
                        color: '#0B407D'
                    },
                }}>

                {menuOptions.map((item, index) => {
                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'profile-menuItem-' + index}
                                      className={item?.isDisabled ? 'dropdownMenuDisable' : 'cursor dropdownMenu'}
                                      onClick={() => {
                                          if (!item?.isDisabled) {
                                              handleMenuItemsClose();
                                              item?.onClick()
                                          }
                                      }}>
                                {item?.icon}
                                {item?.label}
                            </MenuItem>
                        )
                    }

                    return <span key={'profile-menuItem-' + index}/>

                })}
            </Menu>
        );


        return (
            <div onClick={() => {
                // handleSettingIconClick(false)
            }}>
                {icon}
                {menu}
            </div>
        );

    };

    const renderUserName = () =>{
            if(!isEmpty(props?.profile)){
                let profileDetails = props?.profile;
                return(<div className='pe-2'>
                    {getTrimmedName(profileDetails?.firstName+ ' '+profileDetails?.lastName, 20)}
                </div>)
            }
    };

    const renderProfileMenu = () => {

        const {profile, handleItemOpen, handleItemClose, anchorEl} = props;

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            return;
        }
        let profileImage = profile?.firstName?.slice(0, 1) + profile?.lastName?.slice(0, 1);

        let menuOptions = [
            {
                label: 'Change Password',
                onClick: () => {props.history.push('/user/change-password')},
                isVisible: true
            },
            {
                label: 'Logout',
                onClick: () => {
                    if(props.location.pathname === '/model-executions/new'){    // this if statement {} is specially written for the log out action from data setup specially to avoid logout action method first
                        props.history.push('/logoutAction');
                    }else{
                        props.logoutUser();
                        props.history.push('/');
                    }
                },
                isVisible: true
            }

        ];

        let icon = (
            <div className='ms-lg-2 ms-xl-3 ms-2 d-flex align-items-center'>
                {(!isEmpty(profile?.accountId) && isEmpty(allPendingInvitations)) &&  <button
                    className='p-2 buttonStyle secondary-button me-2'
                    style={{width: 'auto'}}
                    onClick={() => {
                        handlingRedirection()
                    }}
                >
                    New Execution Request
                </button>}
                {renderUserName()}
                <Avatar className='avatar-img p-3 cursor'
                        onClick={handleItemOpen}
                        style={{backgroundColor: appTheme.appColor.primary}}>
                    <i className="fa-solid fa-user"/>
                    {/*{profileImage?.toUpperCase()}*/}
                </Avatar>
            </div>
        );

        let menu = (
            <Menu
                id="items-menu"
                anchorEl={anchorEl}
                getcontentanchorel={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={Boolean(anchorEl)}
                onClose={handleItemClose}
                className="p-2"
                style={{
                    top: '11px'
                }}
                disableAutoFocusItem
                PaperProps={{
                    style: {
                        marginLeft: '0px'
                    }
                }}
                MenuListProps={{
                    style: {
                        color: '#0B407D'
                    },
                }}>
                {menuOptions.map((item, index) => {

                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'profile-menuItem-' + index} className="dropdownMenu d-block mx-2"
                                      onClick={() => {
                                          handleItemClose();
                                          item.onClick();
                                      }}>
                                {item?.label}
                            </MenuItem>
                        )
                    }

                    return <span key={'profile-menuItem-' + index}/>

                })}

            </Menu>
        );


        return (
            <div>
                {icon}
                {menu}
            </div>
        );
    };

    const getRoute = (item) => {

        if (item?.isDisabled) {
            return props.currentPathname
        }
        return item?.route
    };

    const getMenuOptions = () => {
        const {account, refreshWindowScrollElement} = props;
        return [
            {
                label: 'Dashboard',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement()
                },
                isVisible: true,
                route: '/dashboard',
                icon: (<i className={'fas fa-briefcase '}/>),
            },
            {
                label: 'Execution Requests',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement();
                },
                isVisible: true,
                route: '/model-executions',
                icon: (<i className={'fas fa-bullhorn'}/>),
            }
            // ,{
            //     label: 'Explore Data',
            //     isDisabled: false,
            //     onClick: () => {
            //         refreshWindowScrollElement();
            //     },
            //     isVisible: true,
            //     route: '/explore-data',
            //     icon: (<i className={'fas fa-bullhorn'}/>),
            // }
        ];
    };

    const renderNavigatorWithOptions = () => {
        const {account, isLoggedOutScreen} = props;

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED)  {
            return;
        }

        return (
            <div className='d-flex'>
                {getMenuOptions().map((item, index) => {
                    if(item?.isVisible){
                        return (
                            <NavLink to={getRoute(item)}
                                     key={index}
                                     className={item?.isDisabled ? 'resAppBarMenuOptionsDisable py-2 headerOptionPadding' : 'resAppBarMenuOptions py-2 headerOptionPadding'}
                                     activeClassName={item?.isDisabled ? '' : "activeMenuOption "}>

                                <div key={'section-' + index}
                                     style={{
                                         opacity: '100%',
                                         paddingTop: 3,
                                         paddingBottom: 3,
                                     }}
                                     className={' border-rounded'}
                                     onClick={() => {
                                         if (!item?.isDisabled) {
                                             item?.onClick()
                                         }
                                     }}>
                                    {item?.label}
                                </div>
                            </NavLink>
                        );
                    }
                })}
            </div>
        )
    };

    const authorizedContentUi = () => {
        const {account} = props;

        return (
            <div className="d-flex justify-content-between align-items-center w-100 " style={{height: "100%"}}>
                <div className='d-flex align-items-center'>
                    <div className={'d-flex align-items-center' + (!isEmpty(profile) && ' cursor')}
                         style={{fontWeight:500, fontSize:24}}
                         onClick={()=>{
                             (!isEmpty(profile)) && props.history.replace('/')
                         }}>
                        <div className='app-logo'>
                            {process.env.REACT_APP_NAME}
                        </div>
                    </div>
                    <div className='ps-4 ms-2'>
                        {(!isEmpty(profile?.accountId) && isEmpty(allPendingInvitations)) && renderNavigatorWithOptions()}
                    </div>
                </div>

                <div className='d-flex align-items-center flex-shrink-0'>
                    {renderProfileMenu()}
                    <div className='d-flex'>
                        {(!isEmpty(profile?.accountId) && isEmpty(allPendingInvitations)) && (account?.isAdmin) && renderSettingMenu()}
                    </div>
                </div>
            </div>
        );

    };

    const unauthorizedContentUi = () => {
        const {isXSView, account} = props;

        return (
            <div className="d-flex justify-content-between align-items-center w-100 " style={{height: "100%"}}>
                <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center cursor'
                         style={{fontWeight:500, fontSize:24}}>
                        <div className='app-logo'>
                            {process.env.REACT_APP_NAME}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if (props?.history?.location?.pathname === '/session-expired') {
        return (
            <AppBar drawerwidth={0}
                    position="fixed"
                    open={true}
                    className='text-black shadow-none d-flex app-bar border-bottom'>
                <Toolbar className='resAppBarContainer toolbar-header bg-white pb-2' style={{
                    minHeight: '54px',
                    paddingLeft: isMobileView ? 15 :100,
                    paddingRight: isMobileView ? 15 :100,
                    paddingTop:8
                }}>
                    {unauthorizedContentUi()}
                </Toolbar>
            </AppBar>
        );
    }
    if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {

        return (
            <div className="res-app-bar-header">
                <AppBar drawerwidth={0}
                        position="fixed"
                        open={true}
                        className='text-black shadow-none d-flex app-bar border-bottom'>
                    <Toolbar className='resAppBarContainer toolbar-header bg-white pb-2' style={{
                        minHeight: '54px',
                        paddingLeft: isMobileView ? 15 :100,
                        paddingRight: isMobileView ? 15 :100,
                        paddingTop:8
                    }}>
                        {authorizedContentUi()}
                    </Toolbar>
                </AppBar>
            </div>
        )
    }

    return <></>
}

ResAppBarView.propTypes = {

};

export default ResAppBarView;