//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLPostLoginRedirectView from "./DLPostLoginRedirectView";
import {connect} from "react-redux";
import {setPostLoginUrl} from "../../actions/appActions";


class DLPostLoginRedirect extends Component {

  render() {
    return (
      <DLPostLoginRedirectView
        {...this.props}
      />
    );
  }
}

DLPostLoginRedirect.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  afterLoginUrl: PropTypes.string.isRequired,
  afterLoginUrlProps: PropTypes.object,
  onClick: PropTypes.func, // this will override the link and linkto attributes
  stopEventPropagation: PropTypes.bool,   // use when you did not want an user event to propagate down. NOTE: not implemented in native
  navigationType: PropTypes.oneOf(['push', 'navigate']),  // note done for only native operation
};

DLPostLoginRedirect.defaultProps = {
  stopEventPropagation: false,
  navigationType: 'push'
}

const mapStateToProps = (state) => ({
  postLoginUrl: state.appState.postLoginUrl,
  postLoginUrlProps: state.appState.postLoginUrlProps,
  userAuthStatus: state.userState.userAuthStatus,
});

export default connect(mapStateToProps, {
  setPostLoginUrl
})(DLPostLoginRedirect);


