import React from 'react';
import {userConst} from "../../../user/userConsts";


function LoggedOutUserView(props) {

    const{loggedOutMessage, userAuthStatus} = props;

    return (
        <div className='d-flex align-items-center justify-content-center w-100' style={{minHeight:'calc(100vh - 164px)', marginTop: (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) ? 0 : 50}}>
            <div>
            <div className='text-center'>
                <div className='my-2' style={{fontSize:'var(--font-size-md)', maxWidth: 600}}>
                    {loggedOutMessage}
                </div>
            </div>
            <div className='d-flex justify-content-center mt-4'>
                <div className='ms-2'>
                    <button
                        className='px-2 py-2 buttonStyle primary-button'
                        onClick={() => {
                            window.location.href ='/';
                            props.logoutUser();
                        }}
                    >
                        {'Home'}
                    </button>
                </div>
            </div>
            </div>
        </div>
    );
}

LoggedOutUserView.propTypes = {

};
export default LoggedOutUserView;
