import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'accountReducer',
    initialState: {
        account:{},
        myPendingInvitations: [],
        isMyPendingInvitationsLoaded: false,
        myPendingRequestDetails: []
    },
    reducers: {
        getMyPendingInvitationData(state, action) {
            state.myPendingInvitations= action.payload?.invitations;
            state.isMyPendingInvitationsLoaded= true
        },
        setMyAccountData(state, action) {
            state.account= action.payload;
        },
        setMyPaymentRequestData(state, action) {
            state.myPendingRequestDetails = action.payload;
        }
    }
});

export const {setMyAccountData, getMyPendingInvitationData,setMyPaymentRequestData} = appSlice.actions;
export default appSlice.reducer
