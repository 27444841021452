import React from 'react';
import {isEmpty} from "../../../utils/helperCommon";
import approvalPending from "../../../assets/images/approvalPending.png"

const PaymentStatusView = (props) => {
    const {errorInApi, planSelected} = props;
    const pendingPaymentUI= () => {
        return(
            <div className='my-2' style={{fontSize:'var(--font-size-md)', fontWeight:'var(--font-weight-semibold)', maxWidth: 600}}>
                <div>
                    <img src={approvalPending} alt='' height= {180} width={180}/>
                </div>
                {'Your account request is pending for approval. You will receive an email upon approval.'}
            </div>
        )
    };

    const approvedPaymentUI = (planName) => {
        const {createAccount} = props;
        return(
            <div>
                <div className='my-2' style={{fontSize:'var(--font-size-xl)', fontWeight:'var(--font-weight-bold)', maxWidth: 600}}>
                    Thank you for subscribing!
                </div>
                <div className='my-2' style={{fontSize:'var(--font-size-md)', fontWeight:'var(--font-weight-semibold)', maxWidth: 600}}>
                   {' Your '+ process.env.REACT_APP_NAME + ' '+ planName +' plan is now Active'}
                </div>
                <button
                    className='py-2 mt-3 px-3 buttonStyle primary-button'
                    style={{width: 'auto'}}
                    onClick={() => {
                        createAccount()
                    }}
                >
                    <span className='px-1'>Start Using</span>
                </button>
            </div>
        )
    };

    const renderUI = () => {
        const {paymentDetails} = props;

        let paymentStatus = paymentDetails?.status;
        if(paymentStatus === 'APPROVED'){
            return approvedPaymentUI(paymentDetails?.planDetails?.name)
        }else{
            return pendingPaymentUI()
        }
    };

    return (
        <div className='pageContainer'>
            {!isEmpty(errorInApi) && <div className='pb-3 pt-3 align-items-center d-flex error-panel'>
                <div className='error-vertical-line'/>
                {errorInApi}
            </div>}
            <div className='d-flex align-items-center justify-content-center w-100' style={{minHeight:'calc(100vh - 164px)'}}>
                <div className=''>
                    <div className='text-center'>
                        {renderUI()}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default PaymentStatusView;