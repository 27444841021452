import React, {useEffect} from 'react';
import AccountStatusView from './accountStatusView';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {isEmpty} from "../../../utils/validations";
import {getMyAccount} from "../../../user/userActions";
import {setMyAccountData} from "../../../account/accountReducer";

const AccountStatus = (props) => {
    const {account} = props;
    useEffect(() => {
        if(isEmpty(account)){
            getMyAccount()
                .then((res) => {
                    if((res?.success) && !isEmpty(res.data)){
                        setMyAccountData(res?.data);
                        if(res?.data?.status === 'INACTIVE'){
                            props.history.replace('/account-status')
                        }
                        if(res?.data?.status === 'ACTIVE'){
                            props.history.replace('/')
                        }
                    }
                });
        }
    }, [props.account,props.profile]);

    return (<AccountStatusView
        {...props}
    />);

};

const mapStateToProps = (state) => ({
    account : state.accountState.account,
    profile : state.userState.profile,
});

export default connect(mapStateToProps, {})(withRouter(AccountStatus));