import React, {Suspense, useEffect, useState} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import ResAppBar from "../../core/layout/drawerContainer/ResAppBar";
import {getAuthorizationToken, removeAuthorizationToken} from "../../utils/httpHelper";
import {
    setCurrentPathname,
    setPrevLocation,
    showSystemErrorBlock
} from "../../core/actions/appActions";
import {connect} from "react-redux";
import Drawer from '@mui/material/Drawer';
import DLToastMessage from "../../core/components/DLToastMessage/DLToastMessage";
import {userConst} from "../../user/userConsts";
import store from "../../store";
import {resetLoggedOutState, setUserAuthStatus, setUserProfile} from "../../user/userReducer";
import {setMyAccountData, setMyPaymentRequestData} from "../../account/accountReducer";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {afterLoginActions, getMyPaymentRequest, getMyProfile} from "../../user/userActions";
import PostLoginRoute from "../../core/webRouteWrapper/PostLoginRoute";
import PageNotAccessible from "../../core/components/PageNotAccessible/PageNotAccessible";
import PageNotFound from "../../core/components/PageNotFound/pageNotFound";
import PrePostLoginRoute from "../../core/webRouteWrapper/PrePostLoginRoute";
import LoggedOutUser from "../../core/components/LoggedOutUser/LoggedOutUser";
import UserDetails from "../../account/userManagement/userDetails/UserDetails";
import Invite from "../../account/invite/Invite";
import TransactionAndPaymentDetails from "../../account/subscription/transactionAndPaymentDetails/TransactionAndPaymentDetails";
import TransactionAndPaymentSuccess from "../../account/subscription/transactionAndPaymentDetails/TransactionAndPaymentSuccess";
import {isEmpty} from "../../utils/validations";
import Modules from "./Modules"
import Subscriptions from "../../account/subscription/Subscriptions";
import PaymentStatus from "../../core/components/PaymentStatus/paymentStatus";
import AccountStatus from "../../core/components/AccountStatus/accountStatus";

const Navigator = (props) => {

    const {isMobileView, userAuthStatus, setUserAuthStatus, setUserProfile, isProfileCallFired,setMyPaymentRequestData} = props;

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isProfileSet, setIsProfileSet] = useState(true);
    let authResponse = getAuthorizationToken();

    useEffect(() => {

        if (authResponse.status) {
            setUserAuthStatus(userConst.AUTH_STATUS_AUTHORIZED);
        } else {
            setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
        }
    }, []);

    useEffect(() => {
        setCurrentPathname(props.location.pathname);
        if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
            getMyProfile().then((res) => {
                if (res.success) {
                    setUserProfile(res?.data);
                    if(res?.data?.isSocketEnabled){
                        store.dispatch(afterLoginActions());
                    }
                    setIsProfileSet(true);
                } else {
                    if(res.statusCode === 401 || res.statusCode === 403){
                        setIsProfileSet(true)
                    }else{
                        setIsProfileSet(false);
                    }
                }
            });

        }

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            setUserProfile(null);
            removeAuthorizationToken();
        }
    }, [userAuthStatus]);

    useEffect(() => {
        const {userStatus, account} = props;

        if (userStatus === userConst.AUTH_STATUS_LOGGED_OUT) {
            props.history.replace('/session-expired');
            store.dispatch(resetLoggedOutState());
        }
        if (props.userStatus === userConst.ACCESS_DENIED) {
            if (isEmpty(account?.id)) {
                props.history.push('/access-denied', {
                    fullUrl: window.location.href,
                    prevPath: props.location.pathname
                });
            }
            store.dispatch(resetLoggedOutState());
        }
    }, [props.userStatus]);


    if (userAuthStatus === userConst.AUTH_STATUS_PENDING) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    if (authResponse.status && !isEmpty(authResponse.token)) {
        if (!isProfileCallFired){
            return (<DLLoader type={"screen"} isVisible={true}/>);
        }
        if (!isProfileSet) {
            return (
                <>
                    <DLToastMessage/>
                </>
            )
        }
    }

    const renderDrawer = () => {
        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            return <></>;
        }

        let contentUi;

        let mobileDrawerUi = (
            <Drawer
                anchor={'left'}
                open={isDrawerOpen}
                sx={{
                    width: '300px',
                    '& .MuiDrawer-paper': {
                        width: '300px',
                    },
                }}
                onClose={() => setIsDrawerOpen(false)}>
                <div style={{
                    background: '#E7ECF2',
                    height: '100vh'
                }}>
                    {contentUi}
                </div>
            </Drawer>
        );

        let desktopDrawerUi = (
            <div style={{
                width: 240,
                height: '100%',
                position: "fixed",
                background: '#E7ECF2'
            }}
                 className={"drawer-height"}>
                {contentUi}
            </div>
        );

        return (
            <div>
                {isMobileView ? mobileDrawerUi : desktopDrawerUi}
            </div>
        );


    };

    const renderAppBar = () => {
        return (
            <ResAppBar openDrawer={() => setIsDrawerOpen(true)}/>

        );
    };

    const renderContent = () => {

        return (
            <div className="w-100 "
                 style={{
                     minHeight: (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) ? 'calc(100vh - 64px)' : '100vh',
                     maxHeight: (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) ? 'calc(100vh - 50px)' : '100vh',
                     overflowY:'auto'
                 }}>

                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        {/*<PreLoginRoute exact path="/" component={Login}/>*/}
                        <PostLoginRoute exact path={"/user-management/:userId"} component={UserDetails}/>
                        <PostLoginRoute exact path={"/transaction"} component={TransactionAndPaymentDetails}/>
                        <PostLoginRoute exact path={"/invite"} component={Invite}/>
                        <PostLoginRoute exact path={"/payment-success"} component={TransactionAndPaymentSuccess}/>
                        <PrePostLoginRoute exact path="/session-expired" component={LoggedOutUser}/>
                        <Route exact path={"/access-denied"} component={PageNotAccessible}/>
                        <PostLoginRoute exact path={"/subscriptions"} component={Subscriptions}/>
                        <PostLoginRoute exact path={"/payment-status"} component={PaymentStatus}/>
                        <PostLoginRoute exact path={"/account-status"} component={AccountStatus}/>
                        {Modules.map(module => {
                            return (<Route key={module.name} {...module.routeProps} />);
                        })}
                        <Route exact path="*" component={PageNotFound}/>

                    </Switch>
                </Suspense>
                <DLToastMessage/>
            </div>
        );
    };

    return (
        <div>
            {renderAppBar()}
            <div style={{marginTop:(userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) ? '50px' : '0px',}}>
                {isDrawerOpen && renderDrawer()}
                {renderContent()}
            </div>
        </div>
    );
};
Navigator.propTypes = {};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    isDrawerOpen: state.appState.isDrawerOpen,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isProfileCallFired: state.userState.isProfileCallFired,
    userStatus: state.userState.userStatus
});

export default connect(mapStateToProps, {
    setUserProfile,
    setMyPaymentRequestData,
    setPrevLocation,
    setUserAuthStatus,
    setMyAccountData

})(withRouter(Navigator));
