import {userConst} from "./userConsts";
import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'userReducer',
    initialState: {
        userAuthStatus: userConst.AUTH_STATUS_PENDING,
        profile: null,
        isDrawerWidth: false,
        userStatus: '',
        loggedOutMessage:'',
        allPendingInvitations: ''
    },
    reducers: {
        GetMyProfileData(state, action) {
            // state.profile= action.payload;
            // state.isProfileLoaded=true;
            state.userAuthStatus=userConst.AUTH_STATUS_AUTHORIZED
        },
        profileCallFired(state, action) {
            state.isProfileCallFired = true
        },
        userLogoutData(state) {
            state.userAuthStatus= userConst.AUTH_STATUS_UNAUTHORIZED;
            state.profile= null;
            state.isLoggedOutScreen = false;
            state.isProfileCallFired = false
        },
        setUserAuthStatus(state, action) {
            state.userAuthStatus = action.payload;
        },
        setUserProfile(state, action) {
            state.profile = action.payload;
        },
        logoutUser(state) {
            state.userAuthStatus = userConst.AUTH_STATUS_UNAUTHORIZED
        },
        setDrawerWidthData(state, action) {
            state.isDrawerWidth = action.payload
        },
        setNotAccessible(state) {
            state.userStatus= userConst.ACCESS_DENIED
        },
        userLoggedOut(state,action) {
            state.userStatus= userConst.AUTH_STATUS_LOGGED_OUT;
            state.isLoggedOutScreen = true;
            state.loggedOutMessage = action?.payload;
        },
        resetLoggedOutState(state){
            state.userStatus = '';
        },
        setPendingInvitations(state, action) {
            state.allPendingInvitations = action?.payload
        },
    }
});

export const {userLoggedOut, userLogoutData,GetMyProfileData, setUserAuthStatus, profileCallFired,
    setUserProfile, resetLoggedOutState, setNotAccessible, setDrawerWidthData, logoutUser,setPendingInvitations} = appSlice.actions;
export default appSlice.reducer
