import React, {useEffect} from 'react';
import LoggedOutUserView from "./LoggedOutUserView";
import {connect} from "react-redux";
import {logoutUser} from "../../../user/userActions";
import {withRouter} from "react-router-dom";
import {isEmpty} from "../../../utils/validations";


function LoggedOutUser(props) {

    useEffect(() => {
        const {loggedOutMessage} = props;
        if(isEmpty(loggedOutMessage)){
            props.history.replace('/user/login')
        }
    },[props.profile]);

    return (
        <LoggedOutUserView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    loggedOutMessage: state.userState.loggedOutMessage,
    profile: state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
});

LoggedOutUser.propTypes = {

};

export default connect(mapStateToProps, {
    logoutUser,
})(withRouter(LoggedOutUser))