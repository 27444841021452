const appTheme = {
    appColor: {
        primary: '#315CCF',
        darkBlue:'#0B407D',
        secondary: '#FF9800',
        success: '#66B821',
        warning: '#FFA827',
        danger: '#Ff0000',
        gray: '#999999',
        lightGray :'#666666',
        darkGray: '#575757',
        grey: '#c5c5c5',
        black: '#242424',
        disabled: '#CDCDCD',
        white: '#fff',
        info: '#03a9f4'
    },
    linkStyle: {
      color: '#315CCF'
    },
    default: {
      fontSize: 14,
      fontFamily: "'Poppins', sans-serif",
      btnBorderType: 'square',
      color:'#282828'
    },
  };

export default appTheme;
