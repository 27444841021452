import {
    httpDelete,
    httpGet,
    httpPost,
    httpPut
} from "../utils/httpHelper";

import store from "../store"
import {
    getMyPendingInvitationData
} from "../account/accountReducer";


export const inviteNewUser = (data,accountId) => {
    return httpPost('/service/mlportal/provisioning/user/invite', data, accountId, true)
        .then((res) => {
            return res;
        })
};
export const acceptInvitation = (data,accountId) => {
    return httpPost('/service/mlportal/provisioning/user/invite/accept', data, accountId, true)
        .then((res) => {
            return res;
        })
};

export const getAllAccountMembers = (accountId,skipCount,limitCount,status) => {
    return httpGet('/service/mlportal/provisioning/user/member/all?skipCount='+skipCount+'&limitCount='+limitCount+'&status='+status, accountId, true)
        .then((res) => {
            return res;
        })
};
export const resendInviteNewUser = (data,accountId) => {
    return httpPost('/service/mlportal/provisioning/user/invite/resend', data, accountId, true)
        .then((res) => {
            return res;
        })
};

export const disableActiveUser = (data,accountId) => {
    return httpPut('/service/mlportal/provisioning/user/member/disable', data, accountId, true)
        .then((res) => {
            return res;
        })
};

export const getEnableUser = (data,accountId) => {
    return httpPut('/service/mlportal/provisioning/user/member/enable', data, accountId, true)
        .then((res) => {
            return res;
        })
};
export const getCancelInvite = (email,accountId) => {
    return httpDelete('/service/mlportal/provisioning/user/invite?email=' + email,'',accountId)
        .then((res) => {
            return res;
        })
};
export const getMyPendingInvitation = () => {
    let url = '/service/mlportal/provisioning/user/invite/pending/all';
    return httpGet(url)
        .then(res => {
            if (res?.success) {
                store.dispatch(getMyPendingInvitationData(res?.data));
            }
            return res;
        })
};

export const submitTransactionDetails = (data,accountId) => {
    return httpPost('/service/mlportal/provisioning/user/payment', data, accountId, true)
        .then((res) => {
            return res;
        })
};
export const createNewAccount = () => {
    return httpPost('/service/mlportal/provisioning/user/account', '', '', true)
        .then((res) => {
            return res;
        })
};
export const getAllPlansData = (accountId) => {
    return httpGet('/service/mlportal/provisioning/plan/all', '', true)
        .then((res) => {
            return res;
        })
};
export const getPlansData = (planCode) => {
    return httpGet('/service/mlportal/provisioning/user/payment/retrievePaymentDetails?planCode='+planCode, '', true)
        .then((res) => {
            return res;
        })
};
export const getCountryNames = () => {
    return httpGet('/service/mlportal/signup/user/country/all', '', true)
        .then((res) => {
            return res;
        })
};

