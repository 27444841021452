import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './assets/css/app.css';
import './assets/css/designToken.css';
import './assets/css/evolvus.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';



const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <GoogleOAuthProvider>
        <App />
    </GoogleOAuthProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
