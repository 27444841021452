import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TransactionAndPaymentDetailsView from "./TransactionAndPaymentDetailsView";
import {isEmpty} from "../../../utils/helperCommon";
import {APP_CONST} from "../../../core/AppConstants";
import {submitTransactionDetails, getPlansData, getCountryNames} from "../../userActions";
import {getMyProfile} from "../../../user/userActions";
import {setNotAccessible, setUserProfile} from "../../../user/userReducer";
import store from "../../../store";
import {getRandomId} from "../../../utils/helper";
import moment from "moment";

const TransactionAndPaymentDetails = (props) => {
    const [transactionState, setTransactionState] = useState({
        errorInApi : '',
        currencySelected: '',
        currencySelectedError: '',
        amount: '',
        amountError: '',
        transactionDate : (moment(new Date()).format('YYYY-MM-DD')),
        transactionDateError : '',
        refNo : getRandomId(5),
        refNoError : '',
        remark : '',
        duration : 'MONTH',
        isshowLoader: false,
        PlanName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        companyNameError : '',
        addressLine1Error : '',
        addressLine2Error : '',
        cityError : '',
        stateError : '',
        countryError : '',
        postalCodeError: '',
        isCoporateAccount: '',
        postalCode: '',
        contryNameObj: '',
        PlanFeatures: {}
    });
    useEffect(()=>{
        getPlanDetails(props.location.state);
        getAllCountryNames();
    },[]);

    const getPlanDetails = (planCode) =>{
        if(!isEmpty(planCode)){
            getPlansData(planCode).then(res => {
                if (res.success) {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        errorInApi : '',
                        isshowLoader: false,
                        currencySelected: res?.data?.currency,
                        PlanName: res?.data?.name,
                        PlanFeatures: res?.data?.features,
                        isCoporateAccount: (res?.data?.code === 'CORPORATE' || res?.data?.code === 'CORPORATE-EXT'),
                        amount: res?.data?.monthlyPrice,
                    }));
                }
                else {
                    if (res?.statusCode === 403) {
                        store.dispatch(setNotAccessible())
                    } else {
                        setTransactionState((prevState) => ({
                            ...prevState,
                            errorInApi: res.__error,
                            isshowLoader: false,
                            isCoporateAccount: false
                        }));
                    }
                }
            });
        }

    };

    const getformatedCountry = (input) => {

        return input.map(item => ({
            label: item.name,
            value: item.name
        }));
    };

    const getAllCountryNames = () =>{
        getCountryNames().then(res => {
            if (res?.success) {
                setTransactionState(prevModelData => ({
                    ...prevModelData,
                    contryNameObj: getformatedCountry(res.data?.country)
                }));
            } else {
                if (res?.statusCode === 403) {
                    store.dispatch(setNotAccessible())
                } else {
                    setTransactionState(prevModelData => ({
                        ...prevModelData,
                        errorInApi: res?.__error
                    }));
                }
            }
        })
    };

    const validateFeild = (name, value) => {

        if((name === 'refNo')){
            if(!isEmpty(value)){
                if(value?.length > 250){
                    setTransactionState((prevState) => ({
                        ...prevState,
                        refNo: value.substring(0, 251),
                        [name + "Error"]: 'Only 250 characters are allowed.'
                    }));
                    return
                }
            }else{
                setTransactionState((prevState) => ({
                    ...prevState,
                    refNo: value,
                    [name + "Error"]: 'Please enter reference number.'
                }));
                return;
            }
        }

        if((name === 'remark')){
            if(!isEmpty(value)){
                if(value?.length > 500){
                    setTransactionState((prevState) => ({
                        ...prevState,
                        remark: value.substring(0, 501),
                        [name + "Error"]: 'Only 500 characters are allowed.'
                    }));
                    return
                }
            }else{
                setTransactionState((prevState) => ({
                    ...prevState,
                    remark: value,
                    [name + "Error"]: ''
                }));
                return;
            }

        }
        setTransactionState((prevState) => ({
            ...prevState,
            [name]: value,
            [name + "Error"]: !isEmpty(value) && ''
        }))
    };

    const validate = (name) => {

        const {transactionDate, refNo,remark, companyName, addressLine1,  addressLine2,  city,  state,  country, postalCode, isCoporateAccount} = transactionState;
        if (name === "transactionDate" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(transactionDate)) {
                setTransactionState((prevState) => ({
                    ...prevState,
                    transactionDateError: 'Please select transaction date.'
                }));
                return false;
            }
            if (transactionDate === 'Invalid date') {
                setTransactionState((prevState) => ({
                    ...prevState,
                    transactionDateError: 'Please select transaction date.'
                }));
                return false;
            }
        }
        if (name === "refNo" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(refNo)) {
                setTransactionState((prevState) => ({
                    ...prevState,
                    refNoError: 'Please enter reference number.'
                }));
                return false;
            }
        }
        if (name === "refNo" || name === APP_CONST.FORM_SUBMISSION) {
            if (!isEmpty(refNo) && (refNo?.length > 250)) {
                setTransactionState((prevState) => ({
                    ...prevState,
                    ["refNoError"]: 'Only 250 characters are allowed.'
                }));
                return false;
            }
        }
        if (name === "remark" || name === APP_CONST.FORM_SUBMISSION) {
            if (!isEmpty(remark) && (remark?.length > 500)) {
                setTransactionState((prevState) => ({
                    ...prevState,
                    ["remarkError"]: 'Only 500 characters are allowed.'
                }));
                return false;
            }
        }
        if(isCoporateAccount){
            if (name === "companyName" || name === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(companyName)) {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        ["companyNameError"]: 'Please enter company name'
                    }));
                    return false;
                }
            }
            if (name === "addressLine1" || name === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(addressLine1)) {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        ["addressLine1Error"]: 'Please enter address line 1'
                    }));
                    return false;
                }
            }
            if (name === "addressLine2" || name === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(addressLine2)) {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        ["addressLine2Error"]: 'Please enter address line 2'
                    }));
                    return false;
                }
            }
            if (name === "city" || name === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(city)) {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        ["cityError"]: 'Please enter city'
                    }));
                    return false;
                }
            }
            if (name === "state" || name === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(state)) {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        ["stateError"]: 'Please enter state'
                    }));
                    return false;
                }
            }
            if (name === "country" || name === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(country)) {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        ["countryError"]: 'Please enter country'
                    }));
                    return false;
                }
            }
            if (name === "postalCode" || name === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(postalCode)) {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        ["postalCodeError"]: 'Please enter postal code'
                    }));
                    return false;
                }
            }
        }

        return true;
    };

    const getAddAccountDetails = (details) => {
        const accountRequestDetails = {};
        //this function is adding those values in obj which are not empty
        for (const [key, value] of Object.entries(details)) {
            if (value !== '' && value !== null && value !== undefined) {
                accountRequestDetails[key] = value;
            }
        }
        return accountRequestDetails;
    };

    const submitPaymentDetails = (e) => {
        e.preventDefault();
        if (!validate(APP_CONST.FORM_SUBMISSION)) {
            return;
        }
        const{account} = props;
        const {currencySelected, amount, transactionDate, refNo, remark, isCoporateAccount,
            companyName, addressLine1, addressLine2, city, state, country , postalCode} = transactionState;

        let data = {
                "planCode": props.location.state,
                "currency": currencySelected,
                "amount": amount,
                "duration": "MONTH",
                "transactionDate": transactionDate,
                "transactionREFNumber": refNo,
                "remarks" : remark
         };
        const details = {
            companyName,
            addressLine1,
            addressLine2,
            city,
            state,
            country,
            postalCode
        };
        if(isCoporateAccount){
            data.accountRequestDetails  =  getAddAccountDetails(details);
        }

        setTransactionState((prevState) => ({
            ...prevState,
            isshowLoader: true
        }));

        submitTransactionDetails(data,account?.id).then(res => {
            if (res.success) {
                getMyProfile()
                    .then((res) => {
                        if(res?.statusCode === 200){
                            store.dispatch(setUserProfile(res?.data));
                        }
                        setTimeout(()=>{
                            setTransactionState((prevState) => ({
                                ...prevState,
                                isshowLoader: false
                            }));
                            props.history.replace('/payment-status')
                        },200);
                    });
                }
            else {
                setTransactionState((prevState) => ({
                    ...prevState,
                    errorInApi : res.__error,
                    isshowLoader: false
                }));
            }
        });

        };

    return (
        <TransactionAndPaymentDetailsView
            {...props}
            {...transactionState}
            validateFeild={validateFeild}
            submitPaymentDetails={submitPaymentDetails}
        />
    );
};
const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isLGView: state.appState.deviceInfo.isLGView,
    isMDView: state.appState.deviceInfo.isMDView,
    account : state.accountState.account,

});

export default connect(mapStateToProps, {})(withRouter(TransactionAndPaymentDetails));