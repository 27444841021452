import React from 'react';

const AccountStatusView = () => {

    return (
        <div className='pageContainer'>
            <div className='d-flex align-items-center justify-content-center w-100' style={{minHeight:'calc(100vh - 164px)'}}>
                <div className='text-center'>
                    <div className='my-2' style={{fontSize:'var(--font-size-md)', maxWidth: 600}}>
                        Your account is inactive. Please contact the account administrator.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountStatusView;