import React from 'react';
import appTheme from "../../../assets/appTheme";
import verificationSuccess from "../../../assets/images/verificationSuccess.png"

function TransactionAndPaymentSuccess(props) {
    return (
        <div className='d-flex flex-column px-4 align-items-center justify-content-center h-100 w-100' style={{minHeight:'calc(100vh - 64px)',backgroundColor: "#F0F0F0", }}>
            <div className='p-5 text-center bg-white mb-4' style={{
                boxShadow:'0px 0px 6px #00000029',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
                maxWidth: 420,
            }}>
                <img src={verificationSuccess} height={50} width={50} />
                <div className='mt-3'
                    style={{
                    fontSize : 'var(--font-size-sm)',
                    fontWeight : 'var(--font-weight-semibold)',
                    color: appTheme.appColor.success
                }}>
                    Thank You for submitting payment details
                </div>
                <div className='mt-3'
                    style={{
                    fontSize : 'var(--font-size-sm)',
                    fontWeight : 'var(--font-weight-semibold)'
                }}>
                    We will confirm your payment and activate your account
                </div>
            </div>
        </div>
    );
}

export default TransactionAndPaymentSuccess;