import React from 'react';
import {useHistory} from 'react-router-dom';
import notfound from "../../../assets/images/notfound.jpg";
import appTheme from "../../../assets/appTheme";

const PageNotFoundView = () => {
    const history = useHistory();

    return (<div className={"h-100"}>
            <div className={" py-md-5 py-5"}>
                <div className={"m-md-5 p-3"}>
                    <div className={"pt-3 text-center"}>
                        <div className='mb-2' style={{fontSize:24, fontWeight: 600}}>Oops!</div>
                        <div className='mb-2'  style={{fontSize:20, fontWeight: 500, color:appTheme.appColor.gray}}>Page Not Found!</div>
                        <div className='mb-2' style={{fontSize:14, fontWeight: 500, color:appTheme.appColor.gray}}>The link you have followed probably broken or the page has been removed.</div>
                    </div>
                    <div className={"pb-2 justify-content-center d-flex align-item-center"}>
                        <img src={notfound}
                             alt={'img'}
                             className={'img-fluid'}
                             width={'300px'}
                             height={'200px'}/>
                    </div>
                    <div className='text-center'>
                        <button
                            className='px-4 py-2 buttonStyle primary-button'
                            onClick={() => {
                                history.push('/')
                            }}>
                            Home
                        </button>
                    </div>
                </div>
            </div>
        </div>);
};

PageNotFoundView.propTypes = {};

export default PageNotFoundView;
