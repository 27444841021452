import {createSlice} from "@reduxjs/toolkit";


const appSlice = createSlice({
    name: 'userReducer',
    initialState: {
        modelData: {},
        executionType: 'ONLINE',
        selectedModelIds: [],
        controlParameterSelected: {},
        attachment: {},
        fileName: '',
        isModelExecutionStarts: false,
        executionRequestId: '',
        modelExecutionOutput: '',
        smilesInput: [],
        liceptorFilters: {},
        seachboxValueUpdated: false,
        clearedFilterName: '',
        modelExecutionsFilter: {
            fromDate: '',
            toDate: '',
            status: {
                label: 'All',
                value: '',
            },
            limitCount: '',
            skipCount: '',
            modelName: {
                label: 'All',
                value: '',
            }
        }
    },
    reducers: {
        setExecutionType(state, action) {
            state.executionType = action.payload?.executionType
        },
        selModelData(state, action) {
            state.modelData = action.payload?.modelData
        },
        setSelectedModelIds(state, action) {
            state.selectedModelIds = action.payload?.selectedModelIds
        },
        setControlParameterSelected(state, action) {
            state.controlParameterSelected = action.payload?.controlParameterSelected
        },
        setUploadedFileDetails(state, action) {
            state.fileName = action.payload?.fileName;
            state.executionRequestId = action.payload?.executionRequestId
        },
        resetAllModelData(state) {
            state.modelData = {};
            state.executionType = 'ONLINE';
            state.selectedModelIds = [];
            state.controlParameterSelected = {};
            state.attachment = {};
            state.fileName = '';
            state.executionRequestId = '';
            state.smilesInput = []
        },
        setModelExecutionState(state, action) {
            state.isModelExecutionStarts = action.payload?.isModelExecutionStarted
        },
        setModelExecutionOuput(state, action) {
            state.modelExecutionOutput = action.payload
        },
        setInputSmiles(state, action) {
            state.smilesInput = action.payload?.smilesInput
        },
        setFilterChildData(state, action) {
            state.liceptorFilters = {...state.liceptorFilters, ...action.payload?.filterChilds};
        },
        setFilterNames(state, action) {
            state.liceptorFilters[action.payload?.key].AllFilterNames = action.payload?.filterNames;
            state.liceptorFilters[action.payload?.key].searchKeyword = action.payload?.searchKeyword;
            state.liceptorFilters[action.payload?.key].isExpanded = action.payload?.isExpanded;
        },
        updateFilterSelection(state, action) {
            state.liceptorFilters[action.payload?.key].selectedFilterNames = action.payload?.selectedFilterNames;
        },
        clearAllFilters(state, action) {
            state.liceptorFilters = action.payload?.liceptorFilters;
            state.seachboxValueUpdated = action.payload?.seachboxValueUpdated;
        },
        clearSelectedFilter(state, action) {
            state.clearedFilterName = action.payload?.clearedFilterName;
        },
        resetSearchValueStatus(state) {
            state.seachboxValueUpdated = false;
        },
        resetSelectedFilterValue(state, action) {
            state.liceptorFilters[action.payload?.key] = action.payload?.filterNames;
            state.clearedFilterName = ''
        },
        setModelListingFilters(state, action) {
            state.modelExecutionsFilter = {
                fromDate: action?.payload?.fromDate,
                toDate: action?.payload?.toDate,
                status: action?.payload?.status,
                limitCount: action?.payload?.limitCount,
                skipCount: action?.payload?.skipCount,
                modelName: action?.payload?.modelName
            }
        },
        resetModelListingFilters(state) {
            state.modelExecutionsFilter = {
                fromDate: '',
                toDate: '',
                status: '',
                limitCount: '',
                skipCount: '',
                modelName: ''
            }
        },
    }
});

export const {
    setExecutionType, selModelData, setSelectedModelIds, setModelExecutionState, resetSearchValueStatus,
    setUploadedFileDetails, resetAllModelData, setModelExecutionOuput, clearAllFilters, clearSelectedFilter, resetModelListingFilters,
    setInputSmiles, setFilterChildData, setFilterNames, updateFilterSelection, resetSelectedFilterValue, setModelListingFilters
} = appSlice.actions;
export default appSlice.reducer
