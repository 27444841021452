import React from 'react';
import {isEmpty} from "../../../utils/helperCommon";
import {getFormattedUserName} from "../../../utils/helper";
import moment from "moment";

function UserDetailsView(props) {
    const {
        userDetails, closeDeleteDialog, handleRemove, isLeaveUserDialogOpen, leaveAccount, account,
        profile, isAccountAdmin, openDeleteDialog, UIState, isRemoveUserDialogOpen,isPlanExpired,isMobileView
    } = props;

    const getStatusValue = (status) => {
        switch (status) {
            case 'ACTIVE':
                return 'Active';
            case 'INACTIVE':
                return 'Inactive';
            default:
        }
    };

    const getRemoveAction = () => {

        if (isAccountAdmin) {

            if (userDetails?.userId === profile?.id) {
                return null;
            }
            return (<div>Remove</div>)

            // return (
            //     <DLButton id={'accountUserDetails-remove'}
            //               label={'Remove'}
            //               buttonSize={"sm"}
            //               fontSize={"md"}
            //               isDisabled={isPlanExpired}
            //               onClick={() => {
            //                   openDeleteDialog('REMOVE_USER')
            //               }}
            //               type={'danger'}
            //               style={{minWidth: "90px"}}
            //     />
            // )
        }

        if (userDetails.userId === profile?.id) {
            return (<div>Leave account</div>)
            // return (
            //     <DLButton id={'accountUserDetails-leaveAccount'}
            //               label={'Leave account'}
            //               type={'danger'}
            //               isPlanExpired={isPlanExpired}
            //               buttonSize={"sm"}
            //               fontSize={"md"}
            //               onClick={() => {
            //                   openDeleteDialog('LEAVE_ACCOUNT')
            //               }}
            //               style={{minWidth: "90px"}}
            //
            //     />
            // )
        }
    };

    // if (UIState === 'NO_CONTENT') {
    //     return (
    //         <></>
    //     )
    // }

    const getNameOrEmail = (nameOrEmail) => {//truncate name after 30 character

        if (isEmpty(nameOrEmail)) {
            return ''
        }

        if (nameOrEmail.length > 15 && isMobileView) {
            return nameOrEmail.slice(0, 15) + '...'
        }

        return nameOrEmail;
    };

    const renderRoleTitle = () => {

        return (
            <div className='page-header w-100 my-4'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className='d-flex align-items-center'>
                        <i className="fa-solid fa-chevron-left pe-2 cursor header-icon"
                           onClick={() => props.history.goBack()} style={{fontSize:16}}/>
                        <div className='header-title'>
                            {'User:'}
                        </div>
                        <div className={'ms-2'} style={{fontWeight:400}}>
                            {getNameOrEmail(getFormattedUserName(userDetails))}
                        </div>
                    </div>
                    {getRemoveAction()}
                </div>
            </div>
        )

    };


    const renderEmptyUI = ()=> {
        return (
            <div className='pageContainer mb-3 mt-4'>
                <div className='panel mt-3'>
                    <div className='d-flex align-items-center justify-content-center empty-records'>
                        User Details not available
                    </div>
                </div>
            </div>)
    };

    return (
        <div className='h-100 '>
            <div className='pageContainer'>
                {isEmpty(UIState) ? renderEmptyUI() :
                    <div>
                        {renderRoleTitle()}
                        <div className='bg-white border-rounded'>
                            <div className='row'>
                                <div className='col-12 mt-md-0'>
                                    <div className=' h-100 panel bg-white p-3'>
                                        <div className='pt-2 px-2'>
                                            <div className='d-flex  align-items-md-center flex-md-row flex-column'>
                                                <div className='' style={{minWidth: 150}}>
                                                    <div className="label">Full Name:</div>
                                                </div>
                                                <div className='text-break'>
                                            <span style={{fontWeight: 500}}>
                                                {isEmpty(getFormattedUserName(userDetails)) ? '' : " " + getFormattedUserName(userDetails)}
                                            </span>

                                                </div>
                                            </div>
                                            <div className='d-flex align-items-md-center flex-md-row flex-column mt-4'>
                                                <div className='' style={{minWidth: 150}}>
                                                    <div className="label">Email Address:</div>
                                                </div>
                                                <div className='text-break'>
                                             <span style={{fontWeight: 500}}>
                                                {isEmpty(userDetails?.email) ? '' :
                                                    <a href={"mailto:" + userDetails?.email}
                                                       className='link-primary'
                                                       style={{fontSize: 14}}>{userDetails?.email}</a>}
                                            </span>
                                                </div>
                                            </div>


                                            {/*TODO:MyProfile screen is pending*/}

                                            <div className='d-flex flex-md-row flex-column mt-4 align-items-md-center'>
                                                <div className='' style={{minWidth: 150}}>
                                                    <div className="label">
                                                        Phone Number:
                                                    </div>
                                                </div>

                                                <div className='text-break'>
                                            <span style={{fontWeight: 500}}>
                                                {
                                                    isEmpty(userDetails?.phoneNumber) ?
                                                        <span className='text-black'>{'--'}</span>
                                                        :
                                                        <a href={"tel:+" + userDetails?.phoneNumber}
                                                           className='link-primary'>{userDetails?.phoneNumber}</a>
                                                }
                                            </span>
                                                </div>
                                            </div>

                                            <div className='d-flex align-items-md-center flex-md-row flex-column mt-4'>
                                                <div className='' style={{minWidth: 150}}>
                                                    <div className="label">
                                                        Joining date:
                                                    </div>
                                                </div>

                                                <div className='text-break'>
                                            <span style={{fontWeight: 500}}>
                                                {isEmpty(userDetails?.joinDate) ? '' : moment(userDetails?.joinDate).format("DD MMM YYYY")}
                                            </span>
                                                </div>
                                            </div>

                                            <div className='d-flex align-items-md-center flex-md-row flex-column mt-4'>
                                                <div className='' style={{minWidth: 150}}>
                                                    <div className="label">Status:</div>
                                                </div>

                                                <div className='text-break'>
                                            <span style={{fontWeight: 500}}>
                                                {isEmpty(userDetails.status) ? '' : <span
                                                    className="badge badge-light-success">{getStatusValue(userDetails.status)} </span>}
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row pt-2 mt-md-2 pb-3'>
                            {/*<div className='col-md-6 col-12 mb-lg-0 mb-2 mb-sm-3 mt-sm-2 pe-md-3'>*/}
                            {/*    <div className=' h-100 panel bg-white  flex-grow-1'>*/}
                            {/*        <UserRoles*/}
                            {/*            userDetails={userDetails} isPlanExpired={isPlanExpired}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className='col-md-6 col-12 mb-lg-0 mb-2 mb-sm-3 mt-md-2'>*/}
                            {/*    <div className=' h-100 panel bg-white  flex-grow-1'>*/}
                            {/*        <UserGroups userDetails={userDetails} isPlanExpired={isPlanExpired}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </div>
                    </div>}
            </div>

            {/*<DLModal maxWidth={"sm"} onClose={() => closeDeleteDialog('LEAVE_ACCOUNT')}*/}
            {/*         open={isLeaveUserDialogOpen}*/}
            {/*         history={props.history}*/}
            {/*         id={'accountUserDetails-leaveAccount-modal'}>*/}
            {/*    <DLModalTitle*/}
            {/*        id={'accountUserDetails-leaveAccount-modalTitle'}*/}
            {/*        onClose={() => closeDeleteDialog('LEAVE_ACCOUNT')}*/}
            {/*        title={'Confirmation'}/>*/}
            {/*    <DLModalContainer id={'accountUserDetails-leaveAccount-modalContainer'}>*/}
            {/*        <div className='mt-4 text-center'>*/}
            {/*            <DLText id={'accountUserDetails-leaveAccount-message'}*/}
            {/*                    type={"normal"}*/}
            {/*                    marginBottom={"sm"}*/}
            {/*                    text={<>*/}
            {/*                        <DLText id={'accountUserDetails-leaveAccount-messageText1'}*/}
            {/*                                type={"normal"}*/}
            {/*                                isInline={true}*/}
            {/*                                fontSize={"md"}*/}
            {/*                                fontWeight={"semi-bold"}*/}
            {/*                                text={'Are you sure you want to leave '}/>*/}
            {/*                        <DLText id={'accountUserDetails-leaveAccount-messageAccountName'}*/}
            {/*                                type={"normal"}*/}
            {/*                                fontSize={"md"}*/}
            {/*                                fontWeight={"semi-bold"}*/}
            {/*                                isInline={true}*/}
            {/*                                text={isEmpty(account?.name) ? '' : account?.name}/>*/}
            {/*                        <DLText id={'accountUserDetails-leaveAccount-messageText2'}*/}
            {/*                                type={"normal"}*/}
            {/*                                fontSize={"md"}*/}
            {/*                                fontWeight={"semi-bold"}*/}
            {/*                                isInline={true}*/}
            {/*                                text={' account?'}/>*/}
            {/*                    </>}/>*/}
            {/*        </div>*/}
            {/*    </DLModalContainer>*/}

            {/*    <DLModalActions id={'accountUserDetails-leaveAccount-actions'}>*/}

            {/*        <div className='mb-4'>*/}
            {/*            <DLButton id={'accountUserDetails-leaveAccount-cancelAction'}*/}
            {/*                      label={'Cancel'}*/}
            {/*                      fontSize={"md"}*/}
            {/*                      buttonSize={"sm"}*/}
            {/*                      styleType={"outlined"}*/}
            {/*                      backgroundColor={"lightGray"}*/}
            {/*                      sideMargin={"sm"}*/}
            {/*                      onClick={() => closeDeleteDialog('LEAVE_ACCOUNT')}*/}
            {/*                      style={{minWidth: "90px"}}*/}
            {/*            />*/}
            {/*            <DLButton id={'accountUserDetails-leaveAccount-leaveAction'}*/}
            {/*                      label={'Leave'}*/}
            {/*                      buttonSize={"sm"}*/}
            {/*                      fontSize={"md"}*/}
            {/*                      sideMargin={"sm"}*/}
            {/*                      onClick={() => leaveAccount()}*/}
            {/*                      style={{minWidth: "90px"}}*/}

            {/*            />*/}
            {/*        </div>*/}
            {/*    </DLModalActions>*/}
            {/*</DLModal>*/}


            {/*<DLModal maxWidth={"sm"} onClose={() => closeDeleteDialog('REMOVE_USER')}*/}
            {/*         open={isRemoveUserDialogOpen}*/}
            {/*         history={props.history}*/}
            {/*         id={'accountUserDetails-removeUser-modal'}>*/}
            {/*    <DLModalTitle id={'accountUserDetails-removeUser-modalTitle'}*/}
            {/*                  onClose={() => closeDeleteDialog('REMOVE_USER')}*/}
            {/*                  title={'Confirmation'}/>*/}
            {/*    <DLModalContainer id={'accountUserDetails-removeUser-modalContainer'}>*/}
            {/*        <div className='mt-4 text-center'>*/}
            {/*            <DLText id={'accountUserDetails-removeUser-message1'}*/}
            {/*                    type={"normal"}*/}
            {/*                    marginBottom={"sm"}*/}
            {/*                    text={<>*/}
            {/*                        <DLText id={'accountUserDetails-removeUser-message1Text1'}*/}
            {/*                                type={"normal"}*/}
            {/*                                isInline={true}*/}
            {/*                                fontWeight={"semi-bold"}*/}
            {/*                                fontSize={"md"}*/}
            {/*                                text={'Are you sure you want to remove '}/>*/}
            {/*                        <DLText id={'accountUserDetails-removeUser-message1UserName'}*/}
            {/*                                type={"normal"}*/}
            {/*                                fontWeight={"semi-bold"}*/}
            {/*                                fontSize={"md"}*/}
            {/*                                isInline={true}*/}
            {/*                                text={isEmpty(getFormattedUserName(userDetails)) ? '' : getFormattedUserName(userDetails)}/>?*/}

            {/*                    </>}/>*/}

            {/*            <DLText id={'accountUserDetails-removeUser-message2'} type={"normal"} marginBottom={"sm"}*/}
            {/*                    text={<>*/}
            {/*                        <DLText id={'accountUserDetails-removeUser-message2UserName'}*/}
            {/*                                type={"normal"}*/}
            {/*                                fontWeight={"semi-bold"}*/}
            {/*                                fontSize={"md"}*/}
            {/*                                isInline={true}*/}
            {/*                                text={isEmpty(getFormattedUserName(userDetails)) ? '' : getFormattedUserName(userDetails)}/>*/}
            {/*                        <DLText id={'accountUserDetails-removeUser-message2Text1'}*/}
            {/*                                type={"normal"}*/}
            {/*                                isInline={true}*/}
            {/*                                fontWeight={"semi-bold"}*/}
            {/*                                fontSize={"md"}*/}
            {/*                                text={' will lose all the access associated with this account.'}/>*/}
            {/*                    </>}/>*/}
            {/*        </div>*/}
            {/*    </DLModalContainer>*/}

            {/*    <DLModalActions id={'accountUserDetails-removeUser-actions'}>*/}

            {/*        <div className='mb-4'>*/}
            {/*            <DLButton id={'accountUserDetails-removeUser-cancelAction'} label={'Cancel'}*/}
            {/*                      styleType={"outlined"}*/}
            {/*                      backgroundColor={"lightGray"}*/}
            {/*                      sideMargin={"sm"}*/}
            {/*                      buttonSize={"md"}*/}
            {/*                      fontWeight={"semi-bold"}*/}
            {/*                      fontSize={"md"}*/}
            {/*                      onClick={() => closeDeleteDialog('REMOVE_USER')}*/}
            {/*                      style={{minWidth: "90px"}}*/}
            {/*            />*/}
            {/*            <DLButton id={'accountUserDetails-removeUser-removeAction'}*/}
            {/*                      label={'Remove'}*/}
            {/*                      buttonSize={"md"}*/}
            {/*                      type={'danger'}*/}
            {/*                      sideMargin={"sm"} onClick={() => handleRemove('REMOVE_USER')}*/}
            {/*                      style={{minWidth: "90px"}}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </DLModalActions>*/}
            {/*</DLModal>*/}
        </div>
    );
}

export default UserDetailsView;