import React from 'react';
import notfound from '../../../assets/images/notfound.jpg';
import appTheme from "../../../assets/appTheme";

const PageNotAccessibleView = (props) => {
   const {handleClick} = props;
    return (
      <div className={""}>
        <div className={"py-2"}>
          <div className={" bg-white m-md-5 p-3"}>
            <div className={"pt-3 text-center"}>
              <div className='mb-2' style={{fontSize:24, fontWeight: 600}}>Oops!</div>
              <div className='mb-2' style={{fontSize:20, fontWeight: 500, color:appTheme.appColor.gray}}>Access Denied</div>
              <div className='mb-2' style={{fontSize:14, fontWeight: 500, color:appTheme.appColor.gray}}>You don't have privilege to access this page.</div>
            </div>
            <div className={"pb-3 justify-content-center d-flex align-item-center"}>
              <img src={notfound} alt={'img'} className={'img-fluid'} width={'300px'} height={'200px'}/>
            </div>
            <div style={{minWidth: '150px', width: 'max-content'}}
                 className=' d-grid mt-2 mx-auto text-center'>
               <button
                  className='px-4 py-2 buttonStyle primary-button'
                  onClick={() => {
                    handleClick()
                  }}>
                Home
              </button>
            </div>
          </div>
        </div>
      </div>
    );
 }

PageNotAccessibleView.propTypes = {};

export default PageNotAccessibleView;
