import { configureStore } from '@reduxjs/toolkit'
import appReducer from "../src/core/reducers/appReducer";
import userReducer from "./user/userReducer";
import ModelReducer from "../src/model/ModelReducer"
import accountReducer from "./account/accountReducer";

const store = configureStore({
  reducer: {
    appState: appReducer,
    userState: userReducer,
    accountState: accountReducer,
    modelState : ModelReducer
  }
});
export default store;
