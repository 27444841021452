import React, {Component} from 'react';
import {CircularProgress} from '@mui/material';
import {connect} from "react-redux";

class DLLoaderView extends Component {
  render() {

    const {type,isVisible,systemError, appLoader} = this.props;

    if(systemError.isVisible){
      return  null;
    }

    if(isVisible || appLoader.isVisible) {
      if(type === 'inline') {
        return (
          <div className="dl-app-loader-container">
            <CircularProgress color={'primary'} />
          </div>
        );
      }

      return (
        <div>
          <div className="dl-app-loader-container">
            <div className="dl-app-loader" />
          </div>
        </div>
      );
    }
      return  null;

  }
}

const mapStateToProps = (state) => ({
  appLoader: state.appState.appLoader,
  systemError: state.appState.systemError
});


export default connect(mapStateToProps, {})(DLLoaderView);
