import {routes} from "./deepLinkRoutes";
import {matchPath} from "react-router-dom";

export const getWebRouteInfoFromUrl =  (pathname) => {

  const generatedRouteOutput =  routes.filter((route) => {
    const matchedData = matchPath(pathname, {
      path: route.webPath,
      exact: true,
      strict: true
    });
    if(!matchedData){
      return null;
    }

    return route;
  });

  if(!generatedRouteOutput.length){
    return null;
  }
  return generatedRouteOutput[0];

};
