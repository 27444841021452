import React, {useEffect, useState} from 'react';
import PaymentStatusView from './paymentStatusView';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {createNewAccount} from "../../../account/userActions";
import {getMyPaymentRequest, getMyProfile} from "../../../user/userActions";
import store from "../../../store";
import {setNotAccessible, setUserProfile} from "../../../user/userReducer";
import {isEmpty} from "../../../utils/validations";

const PaymentStatus = (props) => {

    const [paymentRequestDetails, setPaymentRequestDetails] = useState({
        paymentDetails:[],
        errorInApi:'',
        isshowLoader: false,
        planSelected : ''
    });


    useEffect(() => {
        const {profile, myPendingRequestDetails} = props;
        if(((isEmpty(profile?.accountId) || (profile?.accountId === null)) && !isEmpty(profile)) && isEmpty(myPendingRequestDetails)){
            getMyPaymentRequest()
                .then((res) => {
                    if((res?.statusCode === 200) && !isEmpty(res.data)){
                        setPaymentRequestDetails(prevPaymentData => ({
                            ...prevPaymentData,
                            paymentDetails : res.data,
                            planSelected: res.data?.planDetails?.code
                        }));
                    }
                });
        }
    },[props.profile]);

    useEffect(() => {
        setPaymentRequestDetails(prevPaymentData => ({
            ...prevPaymentData,
            paymentDetails : props.myPendingRequestDetails,
            planSelected: props.myPendingRequestDetails?.planDetails?.code
        }));

    },[props.myPendingRequestDetails]);

    const createAccount = () => {
        let data = {

        };
        createNewAccount(data).then(res => {
            if (res.success) {
                getMyProfile()
                    .then((res) => {
                        if(res?.statusCode === 200){
                            store.dispatch(setUserProfile(res?.data));
                        }
                        setTimeout(()=>{
                            setPaymentRequestDetails((prevState) => ({
                                ...prevState,
                                errorInApi: '',
                                isshowLoader: false
                            }));
                            props.history.replace('/dashboard')
                        },200);
                    });
            }
            else {
                if (res?.statusCode === 403) {
                    store.dispatch(setNotAccessible())
                } else {
                    setPaymentRequestDetails((prevState) => ({
                        ...prevState,
                        errorInApi: res.__error,
                        isshowLoader: false
                    }));
                }
            }
        });

    };

    return (<PaymentStatusView
        {...props}
        {...paymentRequestDetails}
        createAccount = {createAccount}
    />);

};

const mapStateToProps = (state) => ({
    myPendingRequestDetails : state.accountState.myPendingRequestDetails,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(withRouter(PaymentStatus));