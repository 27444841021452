import React, {Component} from 'react';
import DLToastMessageView from './DLToastMessageView';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import PropTypes from "prop-types";
import {hideSystemErrorBlockData, hideToastMessageData} from "../../reducers/appReducer";

class DLToastMessage extends Component {
  render() {
    return (
      <>
        <DLToastMessageView  {...this.props} />
      </>
    );

  }
}

DLToastMessage.propTypes = {
  id: PropTypes.string,  // for testing purpose
};

const mapStateToProps = (state) => ({
  toastStatus: state.appState.toastStatus,
  systemError: state.appState.systemError
});

const mapDispatchToProps = (dispatch) => ({
  closeToastAction: () => {
    dispatch(hideToastMessageData())
  },
  hideSystemErrorBlock: () => {
    dispatch(hideSystemErrorBlockData())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)( withTranslation('translations')(DLToastMessage));
