import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {userConst} from "../../user/userConsts";
// import {APP_CONST} from '../AppConstants';
// import {isEmpty} from '../../utils/validations';
// import EmailVerification from "../../user/components/EmailVerification/emailVerification";

const PrePostLoginRoute = ({component: Component,profile,userAuthStatus, prevLocation, ...rest}) => (
  <Route

    {...rest}
    render={props => {
      // let authStatus = (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED);
      let uiContent;
      // if (authStatus) {
      //   if (isEmpty(profile)) {
      //     uiContent = (<div className="w-100 text-center">
      //     </div>);
      //   } else {
      //     switch (profile.verificationStatus) {
      //       case APP_CONST.USER_STATUS_EMAIL_VERIFICATION_PENDING:
      //         if (props.location.pathname.includes('/user/emailVerification')) {
      //           uiContent = (<EmailVerification/>);
      //         } else {
      //           uiContent = (<Redirect to={'/user/emailVerification'}/>)
      //         }
      //         break;
      //       default:
      //         uiContent = (<Component {...props} prevLocation={prevLocation} />);
      //     }
      //   }
      // } else {
      //   uiContent = (<Component {...props} />);
      // }
        uiContent = (<Component {...props} />);

        return uiContent
    }

    }
  />
);

PrePostLoginRoute.propTypes = {
  // userAuthStatus: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({
  // userAuthStatus: state.userState.userAuthStatus,
  // profile: state.userState.profile,
  // prevLocation: state.appState.prevLocation
});

export default connect(mapStateToProps)(withRouter(PrePostLoginRoute));
