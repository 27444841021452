import {isEmpty} from "../../../utils/helperCommon";
import appTheme from "../../../assets/appTheme";
import React from "react";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import {filterOptions, numberWithCommas} from "../../../utils/helper";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';


function TransactionAndPaymentDetailsView(props) {
    const {errorInApi, PlanName, currencySelected,amount,transactionDate,isSMView, isXSView, remarkError,
        refNo, validateFeild,transactionDateError, refNoError, submitPaymentDetails, remark, isshowLoader,
        isCoporateAccount, companyName, addressLine1, addressLine2, city, state, postalCode, contryNameObj,
        companyNameError, addressLine1Error, addressLine2Error, cityError, stateError, countryError, postalCodeError, PlanFeatures} = props;

    const renderWireTransferDetails = () => {
        return(
            <div className='border-rounded panel' style={{flex: 1}}>
                <div className={"px-0 h-100"}>
                    <div className={"d-flex justify-content-between bottom-border px-3 mx-0"}
                         style={{paddingRight: '7px'}}>
                        <div className="panel-header">
                            Wire Transfer Instructions
                        </div>
                    </div>
                    <div className='px-3 pb-2'>
                        <div className='input-label mt-3'>
                            Bank Name
                        </div>
                        <div className=''>
                            SecureTrust Bank
                        </div>
                        <div className='input-label mt-3'>
                            Bank Address
                        </div>
                        <div className=''>
                            123 Financial Avenue, Cityville, State Country
                        </div>
                        <div className='input-label mt-3'>
                            Account Name
                        </div>
                        <div className=''>
                           XYZ.Ltd
                        </div>

                        <div className='input-label mt-3'>
                            Account Number
                        </div>
                        <div className=''>
                            9876543210
                        </div>

                        <div className='mt-3' style={{
                            color: appTheme.appColor.lightGray
                        }}>
                            Please transfer funds to this account and capture the payment transaction reference number.
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    if(isshowLoader || isEmpty(isCoporateAccount)){
        return (<DLLoader type={"screen"} isVisible={isshowLoader}/>)
    }

    function handleKeyDown(event) {
        if (event?.key === 'Enter') {
            event.preventDefault();
        }
    }

    const handleChange = (event, newValue) => {
        validateFeild('country', newValue?.value);
    };

    const handleDateChange = (date) => {
        let date2 = (moment(date).format('YYYY-MM-DD'));
        if(date2 !== 'Invalid Date'){
            validateFeild('transactionDate',date2)
        }else{
            validateFeild('transactionDate',date2)
        }
    };


    const renderDateUI = () => {
        let isValidDate = moment(transactionDate).isValid();
        return (
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className='dl-date-picker' id={'id'} style={{borderRadius: 6, backgroundColor: "white"}}>
                        <DesktopDatePicker
                            style={{padding: 0, maxHeight: 38, borderRadius: 6}}
                            inputFormat={'dd-MM-yyyy'}
                            mask={"__-__-____"}
                            orientation="portrait"
                            clearable={true}
                            maxDate={new Date()}
                            value={(isValidDate) ? transactionDate : null}
                            onChange={(e) => {handleDateChange(moment(e))}}
                            onKeyDown={(event) => {handleKeyDown(event)}}
                            renderInput={(params) =>
                                <TextField {...params} type="date"
                                           style={{width: 200, maxHeight: 38, padding: 0, borderRadius: 6}}
                                           inputProps={{
                                               ...params.inputProps,
                                               placeholder: 'Select Date',
                                               readOnly: "readonly"
                                           }}
                                           InputProps={{
                                               ...params.InputProps,
                                               endAdornment: (
                                                   <InputAdornment position="end">
                                                       {isValidDate && (!isEmpty(transactionDate)) &&
                                                       <IconButton onClick={() => {
                                                           validateFeild('transactionDate', '')
                                                       }} style={{padding: 0}}>
                                                           <ClearIcon style={{fontSize: 22}}/>
                                                       </IconButton>
                                                       }
                                                       {params.InputProps.endAdornment}
                                                   </InputAdornment>
                                               ),
                                           }}
                                />
                            }
                        />
                    </div>
                </LocalizationProvider>
            </div>
        )
    };

    const renderPaymentDetails = () => {
        return(
                <div className={(isSMView || isXSView) ? 'mt-3 border-rounded panel' : 'border-rounded panel'} style={{flex: isCoporateAccount ? 1 : 1.5}}>
                    <div className={"px-0"}>
                        <div className={"d-flex justify-content-between bottom-border px-3 mx-0 h-100"}
                             style={{paddingRight: '7px'}}>
                            <div className="panel-header">
                                Payment Information
                            </div>
                        </div>
                        <div className={isCoporateAccount ? 'd-flex px-3 py-2' : 'px-3 py-2'}>
                            <div className='pe-3'>
                                <div className='d-flex w-100' style={{gap:10}}>
                                    <div style={{flex: (PlanName === 'Corporate') ? 0.5 : 1}}>
                                        <div className='input-label mt-2'>
                                            Plan Name
                                        </div>
                                        <div className=''>
                                            {isEmpty(PlanName) ? '-' : PlanName}
                                        </div>
                                    </div>
                                    <div style={{flex: isCoporateAccount ? 0.5 :1}}>
                                        <div className='input-label mt-2'>
                                            Currency
                                        </div>
                                        <div className=''>
                                            {currencySelected}
                                        </div>
                                    </div>
                                    <div style={{flex: isCoporateAccount ? 0.5 : 1}}>
                                        <div className='input-label mt-2'>
                                            Amount
                                        </div>
                                        <div className=''>
                                            {numberWithCommas(amount)}
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex w-100' style={{gap:10}}>
                                    <div style={{flex: 1}}>
                                        <div className='input-label mt-3'>
                                            Transaction Date<span style={{color: appTheme.appColor.danger}}> *</span>
                                        </div>
                                        <div className=''>
                                            {renderDateUI()}
                                            {transactionDateError !== '' &&
                                            <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{transactionDateError}</span>
                                            }
                                        </div>
                                        <div className='input-label mt-3'>
                                            Transaction Reference Number<span style={{color: appTheme.appColor.danger}}> *</span>
                                        </div>
                                        <div className=''>
                                            <input type="text"
                                                   id="refNo"
                                                   name="refNo"
                                                   className='cursor'
                                                   style={{
                                                       border: '1px solid '+ appTheme.appColor.grey,
                                                       padding: '8px',
                                                       borderRadius: '6px',
                                                       width: "100%",
                                                       outline: 'none'
                                                   }}
                                                   value={refNo}
                                                   onKeyDown={(event) => {handleKeyDown(event)}}
                                                   onChange={(event) => {
                                                       const inputValue = event.target.value;
                                                       validateFeild('refNo', inputValue)
                                                   }}
                                            />
                                            {refNoError !== '' &&
                                            <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{refNoError}</span>
                                            }
                                        </div>
                                    </div>
                                    {!isCoporateAccount && <div style={{flex: 1}}/>}
                                </div>
                                <div className='input-label mt-3'>
                                    Additional Remarks
                                </div>
                                <div>
                                    <textarea
                                        id="remark"
                                        name="remark"
                                        rows="5"
                                        cols="50"
                                        style={{
                                            border: '1px solid '+(isEmpty(remarkError) ? appTheme.appColor.grey : appTheme.appColor.danger),
                                            padding: '8px',
                                            borderRadius: '6px',
                                            width: "100%",
                                            resize: 'none',
                                            outline: 'none',
                                        }}
                                        aria-expanded={false}
                                        value={remark}
                                        onChange={(event) => {
                                            const inputValue = event.target.value;
                                            validateFeild('remark', inputValue)
                                        }}

                                    >{remark}</textarea>
                                    {!isEmpty(remarkError) && <div style={{fontSize: 'var(--font-size-xs)',color: appTheme.appColor.danger}}>
                                        {remarkError}
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {!isCoporateAccount && <div className='px-3 pb-1 pt-2'>
                            <button
                                className='buttonStyle secondary-button'
                                style={{width: 100}}
                                onClick={() => {
                                    props.history.replace('/subscriptions')
                                }}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className='ms-2 buttonStyle primary-button mb-2'
                                style={{width: 100}}>
                                <span className='px-1'>Submit</span>
                            </button>
                        </div>}
                    </div>
                </div>
            )
    };

    const renderListItem = () => {
        return PlanFeatures.map((item, index) => {
            return (<li className='pt-2'>{item}</li>)
        })
    };

    const renderPersonalAccountDetails = () => {
        return(
                <div className={(isSMView || isXSView) ? 'mt-3 border-rounded panel' : 'border-rounded panel'} style={{flex: isCoporateAccount ? 1 : 1.5}}>
                    <div className={"px-0"}>
                        <div className={"d-flex justify-content-between bottom-border px-3 mx-0 h-100"}
                             style={{paddingRight: '7px'}}>
                            <div className="panel-header">
                                Account Information
                            </div>
                        </div>
                        <div className ='px-3 py-2' style={{height: 'calc(100vh - 567px)'}}>
                            <ul>
                                {renderListItem()}
                            </ul>
                        </div>
                        {!isCoporateAccount && <div className='px-3 pb-1 pt-2'>
                            <button
                                className='buttonStyle secondary-button'
                                style={{width: 100}}
                                onClick={() => {
                                    props.history.replace('/subscriptions')
                                }}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className='ms-2 buttonStyle primary-button mb-2'
                                style={{width: 100}}>
                                <span className='px-1'>Submit</span>
                            </button>
                        </div>}
                    </div>
                </div>
            )
    };

    const renderCompanyDetails = () => {
        return(
            <div className={(isSMView || isXSView) ? 'mt-3 border-rounded panel' : 'border-rounded panel'} style={{flex: isCoporateAccount ? 1 : 1.5}}>
                <div className={"px-0 pb-2"}>
                    <div className={"d-flex justify-content-between bottom-border px-3 mx-0 h-100"}
                         style={{paddingRight: '7px'}}>
                        <div className="panel-header">
                            Company Information
                        </div>
                    </div>
                    <div className={isCoporateAccount ? 'd-flex px-3 py-2 w-50' : 'px-3 py-2 w-50'}>
                        {isCoporateAccount && <div style={{flex: 1}}>
                            <div className='w-100'>
                                <div className='input-label mt-2 w-100'>
                                    Company Name<span style={{color: appTheme.appColor.danger}}> *</span>
                                </div>
                                <div className=''>
                                    <input type="text"
                                           id="companyName"
                                           name="companyName"
                                           className='cursor'
                                           style={{
                                               border: '1px solid '+ appTheme.appColor.grey,
                                               padding: '8px',
                                               borderRadius: '6px',
                                               width: "100%",
                                               outline: 'none'
                                           }}
                                           onKeyDown={(event) => {handleKeyDown(event)}}
                                           value={companyName}
                                           onChange={(event) => {
                                               const inputValue = event.target.value;
                                               validateFeild('companyName', inputValue)
                                           }}
                                    />
                                    {companyNameError !== '' &&
                                    <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{companyNameError}</span>
                                    }
                                </div>

                            </div>
                            <div className='w-100 mt-3'>
                                <div className='input-label w-100'>
                                    Address Line 1<span style={{color: appTheme.appColor.danger}}> *</span>
                                </div>
                                <div className=''>
                                    <input type="text"
                                           id="addressLine1"
                                           name="addressLine1"
                                           className='cursor'
                                           style={{
                                               border: '1px solid '+ appTheme.appColor.grey,
                                               padding: '8px',
                                               borderRadius: '6px',
                                               width: "100%",
                                               outline: 'none'
                                           }}
                                           onKeyDown={(event) => {handleKeyDown(event)}}
                                           value={addressLine1}
                                           onChange={(event) => {
                                               const inputValue = event.target.value;
                                               validateFeild('addressLine1', inputValue)
                                           }}
                                    />
                                    {addressLine1Error !== '' &&
                                    <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{addressLine1Error}</span>
                                    }
                                </div>
                            </div>
                            <div className='w-100 mt-3'>
                                <div className='input-label w-100'>
                                    Address Line 2<span style={{color: appTheme.appColor.danger}}> *</span>
                                </div>
                                <div className=''>
                                    <input type="text"
                                           id="addressLine2"
                                           name="addressLine2"
                                           className='cursor'
                                           style={{
                                               border: '1px solid '+ appTheme.appColor.grey,
                                               padding: '8px',
                                               borderRadius: '6px',
                                               width: "100%",
                                               outline: 'none'
                                           }}
                                           onKeyDown={(event) => {handleKeyDown(event)}}
                                           value={addressLine2}
                                           onChange={(event) => {
                                               const inputValue = event.target.value;
                                               validateFeild('addressLine2', inputValue)
                                           }}
                                    />
                                    {addressLine2Error !== '' &&
                                    <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{addressLine2Error}</span>
                                    }
                                </div>
                            </div>
                            <div className='d-flex' style={{gap: 10}}>
                                <div className='w-100 mt-3'>
                                    <div className='input-label w-100'>
                                        City<span style={{color: appTheme.appColor.danger}}> *</span>
                                    </div>
                                    <div className=''>
                                        <input type="text"
                                               id="city"
                                               name="city"
                                               className='cursor'
                                               style={{
                                                   border: '1px solid '+ appTheme.appColor.grey,
                                                   padding: '8px',
                                                   borderRadius: '6px',
                                                   width: "100%",
                                                   outline: 'none'
                                               }}
                                               onKeyDown={(event) => {handleKeyDown(event)}}
                                               value={city}
                                               onChange={(event) => {
                                                   const inputValue = event.target.value;
                                                   validateFeild('city', inputValue)
                                               }}
                                        />
                                        {cityError !== '' &&
                                        <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{cityError}</span>
                                        }
                                    </div>
                                </div>
                                <div className='w-100 mt-3'>
                                    <div className='input-label w-100'>
                                        State<span style={{color: appTheme.appColor.danger}}> *</span>
                                    </div>
                                    <div className=''>
                                        <input type="text"
                                               id="state"
                                               name="state"
                                               className='cursor'
                                               style={{
                                                   border: '1px solid '+ appTheme.appColor.grey,
                                                   padding: '8px',
                                                   borderRadius: '6px',
                                                   width: "100%",
                                                   outline: 'none'
                                               }}
                                               onKeyDown={(event) => {handleKeyDown(event)}}
                                               value={state}
                                               onChange={(event) => {
                                                   const inputValue = event.target.value;
                                                   validateFeild('state', inputValue)
                                               }}
                                        />
                                        {stateError !== '' &&
                                        <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{stateError}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex' style={{gap: 10}}>
                                <div className='w-100 mt-3'>
                                    <div className='input-label w-100'>
                                        Country<span style={{color: appTheme.appColor.danger}}> *</span>
                                    </div>
                                    <div className='transaction-country'>
                                        <Autocomplete
                                            disablePortal
                                            name="country"
                                            id="combo-box-demo"
                                            options={contryNameObj}
                                            filterOptions={filterOptions}
                                            sx={{
                                                width: '100%',
                                                maxHeight: '38px',
                                                cursor : 'pointer',
                                                '.MuiAutocomplete-option': {
                                                    cursor: 'pointer',
                                                },
                                                '.MuiAutocomplete-input': {
                                                    cursor: 'pointer',
                                                },
                                                '& .MuiInputBase-root': {
                                                    height: '38px',
                                                },
                                                "& button.MuiButtonBase-root" : {
                                                    visibility: "visible"
                                                }
                                            }}
                                            onKeyDown={(event) => {handleKeyDown(event)}}
                                            ListboxProps={{ style: { maxHeight: '150px'} }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                           placeholder="Select Country"
                                                           sx={{
                                                               '.MuiAutocomplete-option': {
                                                                   cursor: 'pointer',
                                                                   fontSize: '14px',
                                                               },
                                                               '.MuiAutocomplete-endAdornment' : {
                                                                   backgroundColor: 'white !important'
                                                               },
                                                               '.MuiAutocomplete-input': {
                                                                   padding: '9px !important'
                                                               },
                                                               '& .MuiInputBase-root': {
                                                                   height: '38px', // Set your desired height here
                                                                   cursor : 'pointer',
                                                                   fontSize: '14px',
                                                                   padding: '0px !important',
                                                               },
                                                           }}
                                                />}
                                            onChange={handleChange}
                                        />
                                        {countryError !== '' &&
                                        <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{countryError}</span>
                                        }
                                    </div>
                                </div>
                                <div className='w-100 mt-3'>
                                    <div className='input-label w-100'>
                                        Postal Code<span style={{color: appTheme.appColor.danger}}> *</span>
                                    </div>
                                    <div className=''>
                                        <input type="text"
                                               id="postalCode"
                                               name="postalCode"
                                               className='cursor'
                                               style={{
                                                   border: '1px solid '+ appTheme.appColor.grey,
                                                   padding: '8px',
                                                   borderRadius: '6px',
                                                   width: "100%",
                                                   outline: 'none'
                                               }}
                                               onKeyDown={(event) => {handleKeyDown(event)}}
                                               value={postalCode}
                                               onChange={(event) => {
                                                   const inputValue = event.target.value;
                                                   validateFeild('postalCode', inputValue)
                                               }}
                                        />
                                        {postalCodeError !== '' &&
                                        <span style={{color: appTheme.appColor.danger, fontSize: 'var(--font-size-xs)'}}>{postalCodeError}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className='px-3 pt-3 pb-2'>
                        {renderButtonUI()}
                    </div>
                </div>
            </div>
        );
    };
    const renderButtonUI = () => {
        return(
            <div>
                <div className='pt-2'>
                    <button
                        className='buttonStyle secondary-button'
                        style={{width: 100}}
                        onClick={() => {
                            props.history.replace('/subscriptions')
                        }}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className='ms-2 buttonStyle primary-button mb-1'
                        style={{width: 100}}>
                        <span className='px-1'>Submit</span>
                    </button>
                </div>
            </div>
        );
    };

    return(
        <div className='pageContainer mt-4'>
            <div className='page-content-bg pe-0 my-2'
                 style={{height: 'calc(100vh - 97px)'}}>
                <div>
                    <div className='d-flex align-items-center w-100'>
                        <i className="fa-solid fa-chevron-left pe-2 cursor header-icon"
                           onClick={() => props.history.goBack()} style={{fontSize:16}}/>
                        <div className='page-header'>
                            {/*Transaction and Payment Details*/}
                            {isCoporateAccount ? 'Company Information' : 'Individual Account'}
                        </div>
                    </div>
                    {!isEmpty(errorInApi) && <div className='py-2 align-items-center d-flex error-panel'>
                        <div className='error-vertical-line'/>
                        {errorInApi}
                    </div>}
                    <div style={{height: 'calc(100vh - 170px)', overflowY: 'auto', paddingRight: 50, maxWidth: '100%'}}>
                        <form onSubmit={(e) => submitPaymentDetails(e)}>
                            <div className={(isSMView || isXSView) ? 'mt-32 w-100' : 'mt-2 d-flex w-100'} style={{
                                gap: 30
                            }}>

                                {/*{renderWireTransferDetails()}*/}
                                {isCoporateAccount && renderCompanyDetails()}
                                {!isCoporateAccount && renderPersonalAccountDetails()}
                                {/*{renderPaymentDetails()}*/}
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransactionAndPaymentDetailsView;