import {
    httpDelete,
    httpGet,
    httpPost,
    httpPostV2, httpPut, removeAuthorizationToken,
    setAuthorizationToken
} from "../utils/httpHelper";
import {userTypes} from "./userTypes";
import store from "./../store";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import {userLogout} from "../core/reducers/appReducer";
import {resetLoggedOutState, userLogoutData,profileCallFired} from "./userReducer";

import {
    acceptNotification,
    disconnect,
    connect, leaveRoom, joinRoom
} from "../utils/socket";
import {setMyAccountData} from "../account/accountReducer";

// let base_api = 'http://localhost:10217'
let base_api = ''

export const registerUser = (obj) => {
    return httpPost('/service/mlportal/signup/user', obj, '', true)
};

export const getRegisteredUserEmail = (id) => {
    return httpGet('/service/provisioning/signup/request?id=' + id);
};

export const sendSignUpRequest = (code, signup_id) => {
    return httpPost('/service/mlportal/signup/user/confirm', {
        code: code,
        requestId: signup_id
    }, '', true);
};

export const resendCodeRequest = (requestId) => {
    return httpPost('/service/mlportal/signup/user/resendVerificationCode', {
        requestId: requestId
    },'',true);
};

export const userLogin = (data) => {
    return httpPost('/service/mlportal/signin/user', data, '', true);
};

export const validateEmail = (email) => {
    return httpPost('/service/mlportal/provisioning/user/password/reset', email,'',true)
};

export const verifyPasswordEmail = (email) => {
    return httpPost('/service/mlportal/provisioning/user/password/validateResetCode', email,'',true)
};

export const resetPassword = (password, userId, code) => {
    return httpPost('/service/mlportal/provisioning/user/password ', {
        password: password,
        userId: userId,
        code: code
    });
};

export const onChangePassword = (data) => {
    return httpPut('/service/mlportal/provisioning/user/password', data,'',true)
        .then(res => {
            return res;
        })
};
export const validateResetLink = (id) => {
    return httpPost('/service/mlportal/user/password/validateResetLink', {code: id})
};

export const setTokenAfterLogin = async (res, rememberMe) => {
    let authResponse = await setAuthorizationToken(res.token, rememberMe);
    return !!authResponse.status;
};


export const getMyProfile = () => {
        return httpGet(base_api+'/service/mlportal/provisioning/user/profile')
            .then(res => {
                store.dispatch(profileCallFired());
                    return res;
                    // Ashwaghosh: -> commented as after login actions should be called only once

                }
            ).catch(()=>{
                store.dispatch(showSystemErrorBlock())
            })
};
export const getMyAccount = () => {
        return httpGet(base_api+'/service/mlportal/provisioning/user/account');
};
export const getMyPaymentRequest = () => {
    return httpGet(base_api+'/service/mlportal/provisioning/user/payment/getRequestForUser');
};
export const setDrawerWidth = (data) => {
    store.dispatch({
        type: userTypes.SET_DRAWER_WIDTH,
        payload: {
            isDrawerWidth: data
        }
    })
};


export const logoutUser = (accountId) => (dispatch) => {
    const {socketStatus} = store.getState().appState.socket;
    if (socketStatus === 'CONNECTED') {
        leaveRoom();
        disconnect();
    }
    removeAuthorizationToken();
    dispatch(userLogoutData());
    dispatch(userLogout());
    dispatch(resetLoggedOutState());
    dispatch(setMyAccountData());
    return true;
};

export const afterLoginActions = () => () => {
    connect();
    acceptNotification();
    joinRoom()
};